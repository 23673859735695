import React, {Component} from 'react';

import {FusePageSimple} from '@fuse';

import OrganizationHeader from './OrganizationHeader';
import OrganizationForm from './OrganizationForm';

import ErrorBoundary from '../errors/ErrorBoundary';

class Organization extends Component {

    render()
    {
        return (
            <React.Fragment>
                <FusePageSimple
                    header={
                        <OrganizationHeader />
                    }
                    content={
                        /*
                        Since the `OrganizationForm` is wrapped in the `ErrorBoundary` component if
                        something breaks in the `OrganizationForm` then things like the navbar and
                        page header will stay in tact. If you look inside the `OrganizationForm`
                        component then you will see a conditional statement that catches an error.
                        */
                        <ErrorBoundary>
                            <OrganizationForm organizations={this.props.organizations} />
                        </ErrorBoundary>
                    }
                />
            </React.Fragment>
        );
    }
}

export default Organization;