import React from 'react';
import {Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles/index';

const styles = theme => ({
	icon: {
		maxHeight: 24
	},
	iconWithMargin: {
		maxHeight: 24,
		marginLeft: 10
	},
});

function MainFooter({classes})
{
    return (
        <div className="flex flex-1 items-center px-24">
            <Typography>2023&copy; JST | 313 E Broad St., Ste 317, Richmond, VA 23219</Typography>
            <div className="flex flex-1 justify-end px-24">
            	<a href="https://www.linkedin.com/company/jst_2/" target="_blank" rel="noopener noreferrer"><img className={classes.icon} src="/assets/images/logos/linkedin.png" alt="The LinkedIn Logo"/></a>
	        	<a href="https://twitter.com/JST_CollectMax" target="_blank" rel="noopener noreferrer"><img className={classes.iconWithMargin} src="/assets/images/logos/twitter.png" alt="The Twitter Logo"/></a>
	        	<a href="https://www.youtube.com/channel/UCAS0v_a02L17C1WyVpwKL8Q" target="_blank" rel="noopener noreferrer"><img className={classes.iconWithMargin} src="/assets/images/logos/youtube.png" alt="The YouTube Logo"/></a>
	        </div>
        </div>
    );
}

export default withStyles(styles, {withTheme: true})(MainFooter);