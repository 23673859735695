import * as Actions from '../actions';
import _ from 'lodash';

const initialState = {
	entities: [],
	organizationId: {},
	bankMethodDialog: {
		type: 'new',
		props: {
			open: false
		},
		data: null
	}
};

const bankMethodsReducer = function(state = initialState, action) {

	switch ( action.type )
	{
		case Actions.GET_BANK_METHODS:
		{
			return {
				...state,
				entities: _.keyBy(action.payload, 'id'),
				organizationId: action.organizationId
			};
		}
		case Actions.OPEN_NEW_BANK_METHOD_DIALOG:
		{
			return {
				...state,
				bankMethodDialog: {
					type: 'new',
					props: {
						open: true
					},
					data: null
				}
			};
		}
		case Actions.CLOSE_NEW_BANK_METHOD_DIALOG:
		{
			return {
				...state,
				bankMethodDialog: {
					type: 'new',
					props: {
						open: false
					},
					data: null
				}
			};
		}
        case Actions.OPEN_REMOVE_BANK_METHOD_DIALOG:
        {
        	return {
        		...state,
        		bankMethodDialog: {
        			type: 'remove',
        			props: {
        				open: true
        			},
        			data: action.data
        		}
        	};
        }
        case Actions.CLOSE_REMOVE_BANK_METHOD_DIALOG:
        {
        	return {
        		...state,
        		bankMethodDialog: {
        			type: 'remove',
        			props: {
        				open: false
        			},
        			data: null
        		}
        	};
        }
		default:
		{
			return state;
		}
	}
};

export default bankMethodsReducer;