import Contact from 'main/content/contact/Contact';
import authRoles from 'auth/authRoles';

export const ContactConfig = {
	auth: authRoles.support,
	routes: [
		{
			path: '/contact',
			component: Contact
		},
	]
};