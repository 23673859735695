import * as Actions from '../actions';
import _ from 'lodash';

/*
Initialize the state to start as blank.
*/
const initialState = {
    organizationInfo: null,
	entities: [],
	inviteEntities: [],
	routeParams: {},
};


const getOrganizations = function (state = initialState, action)
{
	switch (action.type)
	{
        case Actions.GET_ORGANIZATIONS:
		{
			return {
				...state,
				entities: _.keyBy(action.payload, 'id'),
				routeParams: action.organizationParams
			};
		}
        case Actions.GET_ORGANIZATION_INFO:
        {
            return {
                ...state,
                organizationInfo: action.payload,
            };
        }
        case Actions.CREATE_ORGANIZATION:
        {
            const newOrganization = action.payload;
            let entities = [...state.entities];
            entities = [...entities, newOrganization];

            return {
                ...state,
                entities: entities
            };
        }
        case Actions.INVITE_USER:
        {
            const newInvite = action.newInvite;
            let inviteEntities = [...state.inviteEntities];

            inviteEntities = [...inviteEntities, newInvite];
            return {
                ...state,
                inviteEntities: inviteEntities
            };
        }
        case Actions.GET_INVITES:
        {
            return {
                ...state,
                inviteEntities: _.keyBy(action.payload, 'invitationCode'),
                routeParams : action.routeParams
            };
        }
		default:
		{
			return state;
		}
	}
};

export default getOrganizations;