import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, Input, Icon, MenuItem, Select, Typography, Toolbar} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles/index';
import _ from 'lodash';

import * as Actions from './store/actions';
import state from "../../../auth/store/reducers";

const styles = theme => ({
    root       : {},
    formControl: {
        marginBottom: 24
    },
    blueBackground: {
        backgroundColor: '#187DC3'
    },
    buttons               : {
        backgroundColor: '#187DC3',
        width: "12rem"
    }
});
const newUserState = {
    id          : '',
    firstName   : '',
    lastName    : '',
    emailAddress       : '',
    username    : '',
    phoneNumber : '',
    role : '',
    roles: [],
};

class UserDialog extends Component {
    state = {...newUserState};

    componentDidMount()
    {
        this.props.getRoles(this.props.orgId);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        /**
         * After Dialog Open
         */

        /*
        As a side note `userDialog` comes from `user.reducer.js` in the return of the case statement.
        */
        if ( !prevProps.userDialog.props.open && this.props.userDialog.props.open )
        {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.userDialog.type === 'edit' &&
                this.props.userDialog.data &&
                !_.isEqual(this.props.userDialog.data, prevState) )
            {
                this.setState({...this.props.userDialog.data});
            }
        }
    }

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
        console.log(this.state.role)
    };

    handleRoles = event => {
        this.setState({ role: event.target.value });
        console.log(this.state.role)
    };

    closeComposeDialog = () => {
        this.props.userDialog.type === 'edit' ? this.props.closeEditUserDialog() : this.props.closeNewUserDialog();
    };

    canBeSubmitted()
    {
        const {firstName} = this.state;
        return (
            firstName.length > 0
        );
    }

    getFilteredArray = (entities) => {
        return Object.keys(entities).map((id) => entities[id]);
    };

    render()
    {
        const {classes, userDialog, updateUser, roles} = this.props;
        const {isActive} = this.state;

        const rolesData = this.getFilteredArray(roles);

        return (
            <Dialog className={classes.root} {...userDialog.props} onClose={this.closeComposeDialog} fullWidth maxWidth="xs">

                <AppBar position="static" className={classes.blueBackground}>
                    <Toolbar className="flex w-full">
                        <Typography variant="headline" color="inherit">
                            {userDialog.type === 'new' ? 'New Contact' : 'Edit Contact'}
                        </Typography>
                    </Toolbar>
                    <div className="flex flex-col items-center justify-center pb-24">
                        {userDialog.type === 'edit' && (
                            <Typography variant="title" color="inherit" className="pt-8">
                                {this.state.name}
                            </Typography>
                        )}
                    </div>
                </AppBar>

                <DialogContent>
                    <div className="p-24">
                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">account_circle</Icon>
                            </div>
                            <FormControl className={classes.formControl} required fullWidth>
                                <InputLabel htmlFor="firstName">Name</InputLabel>
                                <Input autoFocus id="firstName" name="firstName" defaultValue={this.state.firstName} onChange={this.handleChange}/>
                            </FormControl>
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                            </div>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="lastName">Last name</InputLabel>
                                <Input id="lastName" name="lastName" defaultValue={this.state.lastName} onChange={this.handleChange}/>
                            </FormControl>
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">phone</Icon>
                            </div>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="phoneNumber">Phone</InputLabel>
                                <Input id="phoneNumber" name="phoneNumber" defaultValue={this.state.phoneNumber} onChange={this.handleChange}/>
                            </FormControl>
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">email</Icon>
                            </div>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <Input id="email" name="email" defaultValue={this.state.emailAddress} onChange={this.handleChange}/>
                            </FormControl>
                        </div>

                        <div className="flex">
                            <div className="min-w-48 pt-20">
                                <Icon color="action">account_circle</Icon>
                            </div>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="roles">Roles</InputLabel>
                                <Select
                                    value={this.state.role !== undefined ? this.state.role : ''}
                                    onChange={this.handleRoles}
                                    renderValue={selected => selected}
                                    >
                                    {rolesData.map(role => (
                                      <MenuItem
                                        key={role.id}
                                        value={role.name}
                                        >
                                        {role.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions className="justify-center p-16">
                    <Button
                        className={classes.buttons}
                        variant="raised"
                        color="primary"
                        onClick={() => {
                            updateUser(this.state);
                            this.closeComposeDialog();
                        }}
                        disabled={!this.canBeSubmitted()}
                    >
                        UPDATE
                    </Button>
                    <Button
                        className={classes.buttons}
                        variant="raised"
                        color="primary"
                        onClick={() => {
                            this.setState({
                                isActive: !isActive
                            }, function() {
                                updateUser(state.user);
                                this.closeComposeDialog();
                            })
                        }}
                        disabled={!this.canBeSubmitted()}
                    >
                        {isActive === true ? 'DEACTIVATE' : 'ACTIVATE'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        closeEditUserDialog : Actions.closeEditUserDialog,
        closeNewUserDialog  : Actions.closeNewUserDialog,
        updateUser          : Actions.updateUser,
        getRoles            : Actions.getRoles,
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        userDialog: state.userStore.users.userDialog,
        orgId: state.auth.user.data.orgId,
        roles: state.userStore.users.roles,
    }
}


export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(UserDialog));