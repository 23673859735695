import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FusePageSimple} from '@fuse';
import {bindActionCreators} from 'redux';
import {withRouter, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
//import * as Actions from './store/actions';
import _ from 'lodash';
import {AppBar, Button, Icon, Toolbar, Typography} from '@material-ui/core';
import UsersHeader from './UsersHeader';
import UsersList from './UsersList';
import UsersDialog from './UsersDialog';
import InvitationList from './InvitationList';

import ErrorBoundary from '../errors/ErrorBoundary';

const headerHeight = 160;

const styles = theme => ({
    root                    : {},
    appBar: {
        backgroundColor: "#187DC3"
    },
    avatar                  : {},
    layoutHeader            : {
        height   : headerHeight,
        minHeight: headerHeight
    },
    layoutContentCardWrapper: {
        padding      : 24,
        paddingBottom: 80
    },
    layoutLeftSidebar       : {
        width: 246
    },
    addButton               : {
        backgroundColor: '#187DC3',
        position: 'absolute',
        right   : 12,
        bottom  : 12,
        zIndex  : 99
    }
});

class UsersPage extends Component {

    componentDidMount()
    {
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {   
        }
    }

    render()
    {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <FusePageSimple
                    className={classes.root}
                    classes={{
                        root              : classes.layoutRoot,
                        contentCardWrapper: classes.layoutContentCardWrapper,
                        leftSidebar       : classes.layoutLeftSidebar
                    }}
                    header={
                        <UsersHeader/>
                    }
                    content={
                        <div>
                            <AppBar
                                position="static"
                                className={classes.appBar}
                            >
                                <Toolbar>
                                    <Icon className="text-32 mr-12">people</Icon>
                                    <Typography variant="title" color="inherit" className={classes.flex}>
                                        Users
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <ErrorBoundary>
                                <UsersList/>
                            </ErrorBoundary>

                            <AppBar
                                position="static"
                                className={classes.appBar}
                            >
                                <Toolbar>
                                    <Icon className="text-32 mr-12">people</Icon>
                                    <Typography variant="title" color="inherit" className={classes.flex}>
                                        Invitations
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <ErrorBoundary>
                                <InvitationList/>
                            </ErrorBoundary>
                        </div>
                    }
                    sidebarInner
                    onRef={instance => {
                        this.pageLayout = instance;
                    }}
                />
                <Button
                    component={NavLink}
                    to={'/users/invite'}
                    variant="raised"
                    color="primary"
                    aria-label="add"
                    className={classes.addButton}
                >
                    <Icon className="mr-16">person_add</Icon>
                    INVITE NEW USER
                </Button>

                <UsersDialog/>
                
            </React.Fragment>
        )
    };
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
    //    getUsers         : Actions.getUserList,
    }, dispatch);
}

function mapStateToProps({userStore})
{
    return {
    //    users             : userStore.users.entities,
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPage)));