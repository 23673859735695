import React, {Component} from 'react';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles/index';
import {Button, Card, CardContent, Grow, MenuItem, Typography} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import Formsy from 'formsy-react';
import {FuseUtils, SelectFormsy, TextFieldFormsy} from '@fuse';

import * as Actions from './store/actions';
import * as GlobalActions from '../../../store/actions';

const styles = theme => ({
    root: {
        background    : "url('/assets/images/backgrounds/jst_bg.png') no-repeat",
        backgroundSize: 'cover'
    },
    card: {
        width   : '100%',
        maxWidth: 800
    },
    formsy: {
        width: '100%'
    },
    button: {
        backgroundColor: '#187DC3'
    }
});

const stateList = ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania', 'Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];

const newState = {
    rootAccount: {
        isActive: true,
        organizationId: FuseUtils.generateGUID(),
        adminFirst: '',
        adminLast: '',
        adminEmail: '',
        password: FuseUtils.generateGUID(),
        phoneNumber: '',
        roles: [
            'ROLE_ADMIN'
        ]
    },
    isActive: true,
    name: '',
    address: '',
    status: 'current',
    phoneNumber: '',
}

class Organization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...newState,
            canSubmit: false,
            hasError: null
        };
    }

    componentDidMount()
    {
        /*
        This shows how we try to load all the organizations into props and if something
        happens to not allow us to grab those a function is run to change the state of
        `hasError`. Once `hasError` is `true` the condition right after `render()` will
        throw an error that is then caught by the `<ErrorBoundary>` component that wraps
        this form in `Organization.js`. This will allow us to keep the page in tact and
        render a more visually appealing error block where the form would be. The user
        will still have access to the navigation as well.
        */
//        this.props.getOrganizations(this.props.match.params)
 //       .catch((e) => this.handleError());
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
            this.props.getOrganizations(this.props.match.params);
        }
    }

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    resetForm = () => {
        document.getElementById("form").reset()
    };

    onSubmit = (model) => {
        this.props.createOrganization({
            name: model.name,
            address: model.address + ' ' + model.city + ', ' + model.related + ' ' + model.zip,
            isActive: true,
            phoneNumber: model.phone,
            chargeOverId: parseInt(model.chargeoverId, 10),
            adminFirstName: model.adminFirst,
            adminLastName: model.adminLast,
            adminEmail: model.adminEmail,
        }).then(() => {
            this.props.showMessage({message: model.name + " organization created, and " + model.adminFirst + " invited."})
            this.form.reset()
        }).catch((error) => {
            console.log(error);
            this.props.showMessage({message: "Error creating " + model.name + " organization."})
        })
    };

    handleError() {
        this.setState({hasError: true});
    }

    render()
    {
        const {classes} = this.props;
        const {canSubmit} = this.state;

        if (this.state.hasError === true) {
            throw new Error('I crashed!');
        }

        return (
            <div className={classNames(classes.root,"flex flex-col flex-auto flex-no-shrink items-center justify-center p-32")}>
                <div className="flex flex-col items-center justify-center w-full">
                    <Grow in={true}>
                        <Card className={classes.card}>
                            <CardContent className="flex flex-col items-center justify-center p-32">
                                <img className="w-128 m-32" src="assets/images/logos/jst_logo.png" alt="logo"/>

                                <Typography variant="title" className="mt-16">REGISTER A NEW ORGANIZATION</Typography>
                                <Typography style={{ color: 'red' }} className="mb-32" paragraph>*All of the fields are required</Typography>

                                <Formsy
                                    onValidSubmit={this.onSubmit}
                                    onValid={this.enableButton}
                                    onInvalid={this.disableButton}
                                    ref={(form) => this.form = form}
                                    className={classNames(classes.formsy, "flex flex-col justify-center")}
                                >
                                    <TextFieldFormsy
                                        className="mb-24"
                                        type="text"
                                        name="name"
                                        label="Name"
                                        validations="isExisty"
                                        validationErrors={{
                                            isExisty: 'There must be a value for last name'
                                        }}c
                                        required
                                    />
                                    <TextFieldFormsy
                                        className="mb-24"
                                        type="text"
                                        name="address"
                                        label="Address"
                                        validations="isExisty"
                                        validationErrors={{
                                            isExisty: 'There must be a value for last name'
                                        }}
                                        required
                                    />
                                    <TextFieldFormsy
                                        className="mb-24"
                                        type="text"
                                        name="city"
                                        label="City"
                                        validations="isExisty"
                                        validationErrors={{
                                            isExisty: 'There must be a value for last name'
                                        }}
                                        required
                                    />
                                    <SelectFormsy
                                        className="my-24"
                                        name="related"
                                        label="Select a state"
                                        value=""
                                        /*required*/
                                    >
                                        {stateList.map(state =>
                                            <MenuItem
                                                key={state}
                                                value={state}
                                            >
                                                {state}
                                            </MenuItem>
                                        )}
                                    </SelectFormsy>
                                    <TextFieldFormsy
                                        className="mb-24"
                                        type="text"
                                        name="zip"
                                        label="Zip"
                                        validations="isExisty"
                                        validationErrors={{
                                            isExisty: 'There must be a value for last name'
                                        }}
                                        required
                                    />
                                    <TextFieldFormsy
                                        className="mb-24"
                                        type="text"
                                        name="phone"
                                        label="Phone"
                                        validations="isExisty"
                                        validationErrors={{
                                            isExisty: 'There must be a value for last name'
                                        }}
                                        required
                                    />
                                    <TextFieldFormsy
                                        className="mb-24"
                                        type="text"
                                        name="adminFirst"
                                        label="Admin First Name"
                                        validations="isExisty"
                                        validationErrors={{
                                            isExisty: 'There must be a value for last name'
                                        }}
                                        required
                                    />
                                    <TextFieldFormsy
                                        className="mb-24"
                                        type="text"
                                        name="adminLast"
                                        label="Admin Last Name"
                                        validations="isExisty"
                                        validationErrors={{
                                            isExisty: 'There must be a value for last name'
                                        }}
                                        required
                                    />
                                    <TextFieldFormsy
                                        className="mb-24"
                                        type="text"
                                        name="adminEmail"
                                        label="Admin Email"
                                        validations="isEmail"
                                        validationErrors={{
                                            isEmail: 'Must be a valid email address'
                                        }}
                                        required
                                    />
                                    <TextFieldFormsy
                                        className="mb-24"
                                        type="text"
                                        name="chargeoverId"
                                        label="Chargeover ID"
                                        validations="isInt"
                                        validationErrors={{
                                            isInt: 'Chargeover ID must be an integer greater than 0.'
                                        }}
                                        required
                                    />
                                    <Button
                                        type="submit"
                                        variant="raised"
                                        color="secondary"
                                        className="mx-auto mt-16"
                                        aria-label="CREATE ORGANIZATION"
                                        disabled={!canSubmit}
                                    >
                                        CREATE ORGANIZATION
                                    </Button>
                                </Formsy>

                            </CardContent>
                        </Card>
                    </Grow>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getOrganizations: Actions.getOrganizations,
        createOrganization: Actions.createOrganization,
        getInvites: Actions.getInvites,
        showMessage: GlobalActions.showMessage,
    }, dispatch);
}

function mapStateToProps({organizationsStore, userStore})
{
    return {
        invites: organizationsStore.organization.inviteEntities,
    }
}

export default withStyles(styles, {withTheme: true}) (withRouter(connect(mapStateToProps, mapDispatchToProps)(Organization)));