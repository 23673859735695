import React from 'react'

export default function svgDocx({className = 'svg-icon', fill = '#000'}) {
    return (
		<svg className={className} fill={fill}  height='100px' width='100px' x="0px" y="0px" viewBox="0 0 90.527 100.001"><rect x="16.842" y="56.843" fill="#ffffff" width="37.895" height="6.315"></rect><rect x="16.842" y="37.895" fill="#ffffff" width="37.895" height="6.315"></rect><rect x="16.842" y="18.948" fill="#ffffff" width="37.895" height="6.315"></rect><path fill="#ffffff" d="M86.316,14.738H71.58V4.21c0-2.632-1.578-4.21-4.211-4.21H4.21C1.578,0,0,1.578,0,4.21v77.895
			c0,2.634,1.578,4.213,4.21,4.213h14.737v9.474c0,2.632,1.579,4.21,4.211,4.21h63.158c2.633,0,4.211-1.578,4.211-4.21V18.948
			C90.527,16.315,88.949,14.738,86.316,14.738z M6.316,77.896V8.421c0-1.316,0.789-2.106,2.105-2.106h54.737
			c1.316,0,2.105,0.79,2.105,2.106v69.475c0,1.315-0.789,2.104-2.105,2.104H8.421C7.105,80,6.316,79.211,6.316,77.896z M84.21,91.579
			c0,1.316-0.789,2.104-2.104,2.104H28.421c-1.316,0-2.105-0.787-2.105-2.104v-5.264h41.053c2.633,0,4.211-1.578,4.211-4.211V22.106
			h10.526c1.315,0,2.104,0.788,2.104,2.104V91.579z"></path></svg>	
	)
}

