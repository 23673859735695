import PaymentMethods from './PaymentMethods';
import authRoles from "../../../auth/authRoles";

export const PaymentMethodsConfig = {
    auth: authRoles.billing,
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/payment-methods',
            component: PaymentMethods
        }
    ]
};
