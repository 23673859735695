import React, {Component} from 'react';
import {Button, Dialog, DialogActions, Icon, MenuItem, Typography, Toolbar, AppBar} from '@material-ui/core';
import {withStyles} from '@material-ui/core/index';
import {bindActionCreators} from 'redux';
import * as Actions from './store/actions';
import {connect} from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import Formsy, {addValidationRule} from 'formsy-react';
import {SelectFormsy, TextFieldFormsy} from '@fuse';

const styles = theme => ({
	root: {},
	formControl: {
		marginBottom: 24
	},
    formFields: {
        width: '100%'
    },
    formIcons: {
        color: '#187DC3'
    },
    formItems: {
        display: 'flex',
        alignItems: 'center'
    },
    blueBackground: {
        backgroundColor: "#187DC3"
    }
});

const newBankMethodState = {
	id: '',
	bankAccountNumber: '',
	routingNumber: '',
	bankName: '',
	nameOnAccount: ''
};

addValidationRule('isEqual', (values, value, secondField) => {
    return value === values[secondField]
});

const type = [
    'CHECKING',
    'SAVINGS',
    'BUSINESS',
];

class BankMethodsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...newBankMethodState,
            canSubmit: false,
            hasError: null
        };
    }

    handleError() {
        this.setState({ hasError: true });
    }

	componentDidUpdate(prevProps, prevState, snapshot)
	{
		/**
		After Dialog Open
		*/
		if ( !prevProps.bankMethodDialog.props.open && this.props.bankMethodDialog.props.open )
        {
            /**
             * Dialog type: 'remove'
             * Update State
             */
            if ( this.props.bankMethodDialog.type === 'remove' &&
                this.props.bankMethodDialog.data &&
                !_.isEqual(this.props.bankMethodDialog.data, prevState) )
            {
                this.setState({...this.props.bankMethodDialog.data});
            }

			/**
			Dialog type: 'new'
			Update State
			*/
			if ( this.props.bankMethodDialog.type === 'new' &&
                !_.isEqual(newBankMethodState, prevState) )
            {
                this.setState({...newBankMethodState});
            }
		}
		return null;
	}

	handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    handleAccountType = event => {
        console.log(event);
        this.setState({ type: event.target.value });
    };

	closeComposeDialog = () => {
		this.props.bankMethodDialog.type === 'edit' ? this.props.closeEditBankMethodDialog() : this.props.closeNewBankMethodDialog();
	};

    disableButton = () => {
        console.info("Disable button called");
        this.form.enableValidations = true;
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        console.info("Enable button called.");
        if(this.form != null) {
        
            if(this.form.getModel().bankAccountNumber === undefined) {
                this.setState({canSubmit: false});
            } else {
                this.setState({canSubmit: true});
            }
        }
    };

    onSubmit = (bankInfo) => {
        const {addBankMethod} = this.props;
        addBankMethod(bankInfo, this.props.orgId);
        this.closeComposeDialog()
    };

	render()
	{
		const {classes, bankMethodDialog, removeBankMethod} = this.props;
        const {canSubmit} = this.state;

        if (this.state.hasError === true) {
            throw new Error('I crashed!');
        }

		return (
			<Dialog className={classes.root} {...bankMethodDialog.props} onClose={this.closeComposeDialog} fullWidth maxWidth="xs">

				<AppBar position="static">
					<Toolbar className={classNames(classes.blueBackground, "flex w-full")}>
						<Typography variant="subheading" color="inherit">
							{bankMethodDialog.type === 'new' ? 'New Bank Method' : 'Remove Bank Method'}
						</Typography>
					</Toolbar>
				</AppBar>

                {bankMethodDialog.type === 'new' ? (
                    <Formsy
                        onValidSubmit={this.onSubmit}
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        ref={(form) => this.form = form}
                        className="flex flex-col justify-center mx-24 my-24"
                    >

                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="routingNumber"
                                label="Routing Number"
                                validations="isNumeric,minLength:5"
                                validationErrors={{
                                    isNumeric: "Must be all numbers",
                                    minLength: "Must be more than 5 digits long"
                                }}
                                required
                            />
                        </div>

                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="bankAccountNumber"
                                label="Bank Account Number"
                                validations="isNumeric,minLength:5"
                                validationErrors={{
                                    isNumeric: "Must be all numbers",
                                    minLength: "Must be more than 5 digits long"
                                }}
                                required
                            />
                        </div>

                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="confirmBankAccountNumber"
                                label="Confirm Bank Account Number"
                                validations="isEqual:bankAccountNumber"
                                validationErrors={{
                                    isEqual: "Fields do not match"
                                }}
                                required
                            />
                        </div>

                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <SelectFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                name="type"
                                label="Type"
                                value={this.state.type !== undefined ? this.state.type : ""}
                                onChange={this.handleAccountType}
                            >
                                {type.map(method => (
                                  <MenuItem
                                    key={method !== undefined ? method : ""}
                                    value={method !== undefined ? method : ""}
                                    >
                                    {method !== undefined ? method : ""}
                                  </MenuItem>
                                ))}
                            </SelectFormsy>
                        </div>

                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>person</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="nameOnAccount"
                                label="Name On Account"
                                validations={{
                                    minLength: 2
                                }}
                                validationErrors={{
                                    minLength: "Must be more than 2 characters"
                                }}
                                required
                            />
                        </div>

                        <Button
                            type="submit"
                            variant="raised"
                            color="secondary"
                            className={classNames(classes.button, "normal-case mt-24 mb-24 max-w-192")}
                            aria-label="ADD BANK ACCOUNT"
                            disabled={!canSubmit}
                        >
                            ADD BANK ACCOUNT
                        </Button>

                    </Formsy>
				) : (
                    <div>
                        <Typography className="h2 p-24">Are you sure you want to delete payment method ending in {this.state.maskedAccount}?</Typography>
                        <DialogActions className="justify-between pl-16">
                            <div className="mb-24">
                                <Button
                                    className={classes.blueBackground}
                                    variant="raised"
                                    color="primary"
                                    onClick={() => {
                                        removeBankMethod(this.state.id, this.props.orgId)
                                        .catch((e) => this.handleError());
                                        this.closeComposeDialog();
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    className={classNames(classes.blueBackground, "ml-24")}
                                    variant="raised"
                                    color="primary"
                                    onClick={() => {
                                        this.closeComposeDialog();
                                    }}
                                >
                                    No
                                </Button>
                            </div>
                        </DialogActions>
                    </div> 
                )}
			</Dialog>
		);
	}
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        closeNewBankMethodDialog    : Actions.closeNewBankMethodDialog,
        addBankMethod               : Actions.addBankMethod,
        removeBankMethod            : Actions.removeBankMethod
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        bankMethodDialog: state.bankMethodsReducer.bankMethods.bankMethodDialog,
        orgId: state.auth.user.data.orgId
    }
}


export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(BankMethodsDialog));
