import * as Actions from '../actions';

const initialState = {
    key: null,
    date: null,
    showKey: false,
};

const activationReducer = function (state = initialState, action) {
    switch (action.type)
    {
        case Actions.POST_ACTIVATE_CODE:
        {
            return {
                ...state,
                key: action.payload.key,
                date: action.payload.date
            };
        }
        case Actions.SHOW_ACTIVATION_CODE:
        {
            return {
                ...state,
                showKey: action.payload
            };
        }
        default:
        {
            return state;
        }
    }
};

export default activationReducer;