import axios from 'axios/index';

export const GET_SUBSCRIPTIONS = '[SUBSCRIPTIONS STORE] GET SUBSCRIPTIONS';
export const GET_SUBSCRIPTION  = '[SUBSCRIPTIONS STORE] GET SUBSCRIPTION';
export const UPDATE_AUTOPAY_DATA = '[SUBSCRIPTIONS STORE] UPDATE AUTOPAY'

export function getSubscriptions(organizationId) {
    const getSubscription = axios.get('/api/subscription/', {
        params: {orgId: organizationId}
    });

    return (dispatch) => {
        return getSubscription.then((response) => {
            return dispatch({
                type: GET_SUBSCRIPTIONS,
                payload: response.data
            })
        }, e => console.error(e));
    }
}

export function getSubscription(subscriptionId, organizationId) {
    const getSubscription = axios.get(`/api/subscription/${subscriptionId}`, {
        params: {orgId: organizationId}
    })
    return (dispatch) => {
        return getSubscription.then((response) => {
            return dispatch({
                type: GET_SUBSCRIPTION,
                payload: response.data,
                subscriptionId
            })
        }, e => console.error(e));
    }
}