import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';
import {FusePageSimple} from '@fuse';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import * as Actions from './store/actions';
import PaymentMethodsList from 'main/content/payment-methods/PaymentMethodsList';
import PaymentMethodsHeader from 'main/content/payment-methods/PaymentMethodsHeader';
import _ from 'lodash';
import ErrorBoundary from "../errors/ErrorBoundary";

const styles = theme => ({
    layoutRoot: {
        display: "block"
    },
    layoutContentCardWrapper: {
        padding      : 24,
        paddingBottom: 80
    },
});

class PaymentMethods extends Component {

    componentDidMount()
    {
        //TODO: Remove this constant and source the correct data
        this.props.getCreditMethods(this.props.orgId);
        this.props.getBankMethods(this.props.orgId);
    }
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
            this.props.getCreditMethods(this.props.match.params);
            this.props.getBankMethods(this.props.match.params);
        }
    }

    render()
    {
        const {classes} = this.props;
          
        return (
            <React.Fragment>
                <FusePageSimple
                    classes={{
                        root: classes.layoutRoot
                    }}
                    header={
                      <PaymentMethodsHeader pageLayout = {() => this.pageLayout}/>
                    }
                    content={
                        <ErrorBoundary>
                            <PaymentMethodsList/>
                        </ErrorBoundary>
                    }
                    sidebarInner
                    onRef={instance => {
                        this.pageLayout = instance;
                    }}
                />
            </React.Fragment>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getCreditMethods: Actions.getCreditMethods,
        getBankMethods: Actions.getBankMethods
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        creditMethods: state.creditMethodsReducer.creditMethods.entities,
        bankMethods: state.bankMethodsReducer.bankMethods.entities,
        orgId: state.auth.user.data.orgId
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentMethods)));