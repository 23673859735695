import * as Actions from '../actions';
import _ from 'lodash';

const initialState = {
	invoiceEntities: [],
	creditMethodEntities: [],
    bankMethodEntities: [],
	selectedInvoiceIds: [],
    selectedInvoiceBalances: [],
	selectedCreditMethod: '',
    selectedBankMethod: '',
	routeParams: {},
    balance: 0,
    confirmationId: ""
};

const invoicesReducer = function (state = initialState, action)
{
	switch (action.type)
    {
        case Actions.GET_BALANCE:
        {
            let balance = _.sum(_.map(action.payload, 'balance')).toFixed(2);

            return {
                ...state,
                balance
            };
        }
		case Actions.GET_INVOICES:
		{
		    let invoices = _.orderBy(action.payload, 'state', 'desc');

			return {
				...state,
				invoiceEntities: invoices,
				routeParams: action.routeParams,
			};
        }
        case Actions.MAKE_PAYMENT:
        {
            const selectedInvoice = state.invoiceEntities.find(inv => inv.id === state.selectedInvoice)
            const showThanks = selectedInvoice.cyclePreFromDate || selectedInvoice.cyclePreToDate || selectedInvoice.cyclePostFromDate || selectedInvoice.cyclePostToDate
            return {
                ...state,
                showThanks: (action.payload.success && 'yes' === action.payload.success),
                confirmationId: action.payload.id
            };
        }
        case Actions.FAILED_PAYMENT: 
        {
            return {
                ...state,
                showThanks: false,
                paymentError: true,
                paymentErrorMsg: action.payload.data.message
            };
        }
		case Actions.SELECT_ALL_INVOICES:
		{

            const selectedInvoiceIds = _.map(_.filter(state.invoiceEntities, {'state': 'o'}),'id');

            return {
                ...state,
                selectedInvoiceIds: selectedInvoiceIds
            };
        }
        case Actions.SELECT_INVOICE:
        {
            return {
                ...state,
                paymentError: false,
                showThanks: false,
                selectedInvoice: action.invoiceId
            }
        }
		case Actions.DESELECT_ALL_INVOICES:
        {
            return {
                ...state,
                selectedInvoiceIds: []
            };
        }
        case Actions.TOGGLE_IN_SELECTED_INVOICES:
        {
            const invoiceId = action.invoiceId;
            let selectedInvoiceIds = state.selectedInvoiceIds;
            if (state.selectedInvoiceIds.find(id => id === invoiceId))
            {
                selectedInvoiceIds = selectedInvoiceIds.filter(id => id !== invoiceId);
            }
            else
            {
                selectedInvoiceIds.push(invoiceId);
            }

            return {
                ...state,
                selectedInvoiceIds: selectedInvoiceIds,
            };
            
        }
        case Actions.GET_CREDIT_METHODS:
		{
			return {
				...state,
				creditMethodEntities: _.keyBy(action.payload, 'id'),
				routeParams: action.routeParams
			};
		}
		case Actions.TOGGLE_IN_SELECTED_CREDIT_METHOD:
        {
        	const creditMethodId = action.creditMethodId;
            let selectedCreditMethod = [...state.selectedCreditMethod];

            if ( selectedCreditMethod.find(id => id === creditMethodId) !== undefined )
            {
                selectedCreditMethod = selectedCreditMethod.filter(id => id !== creditMethodId);
            }
            else
            {
                selectedCreditMethod = [...selectedCreditMethod, creditMethodId];
            }

            return {
                ...state,
                selectedCreditMethod: selectedCreditMethod
            };
        }
        case Actions.GET_BANK_METHODS:
        {
            return {
                ...state,
                bankMethodEntities: _.keyBy(action.payload, 'id'),
                routeParams: action.routeParams
            };
        }
		default:
		{
			return state;
		}
	}
};

export default invoicesReducer;