import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {lightGreen, grey, blue} from '@material-ui/core/colors'
import {AppBar, Button, Card, CardContent, Paper, Chip, Icon, Toolbar, Typography, Snackbar, SnackbarContent} from '@material-ui/core';
import AutoPayModal from './AutoPayModal'
import classNames from 'classnames';

const styles = theme => ({
	appBar: {
		backgroundColor: "#187DC3"
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'row'
    },
    toolBar: {
        justifyContent: 'space-between'
    },
    card: {
        minHeight: 200,
        position: 'relative'
    },    
    cardContent: {
        position: 'relative'
    },
    cancelButton: {
        float: 'right',
        color: "#187DC3",
    },
    editButton: {
        color: "#187DC3",
    },
    drawerButton: {
        backgroundColor: grey[100],
        color: blue[300],
        position: 'absolute',
        bottom: 0,
        right:0,
        background: '#fff',
        padding: 20,
        zIndex: 1
    }, 
    nickName: {
        marginLeft: '2rem',
        fontWeight: 300
    },
    dropdownMenu: {
        position: 'absolute',
        top: 0,
        right:0,
        background: '#fff',
        padding: 20,
        zIndex: 2
    }
})

class Product extends Component {

    chipLimit = 5

    state = {
        drawerOpen: false,
        menuOpen: false,
        modalOpen: false
    }

    handleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })   
    }

    openModal = () => {
        this.setState({
            modalOpen: true
        })        
    }

    closeModal = () => {
        this.setState({
            modalOpen: false,
            menuOpen: false
        })             
    }

    handleDrawer = () => {
        this.setState({
            drawerOpen: !this.state.drawerOpen
        })
    }    

    findCard = id => {
        const {cards} = this.props;
        return cards[id] || {};
    }

    findAccount = id => {
        const {accounts} = this.props;
        return accounts[id] || {};        
    }

    findPaymentMethod = product => {
        switch(product.payMethod) {
            case 'ach':
                return this.findAccount(product.achId)
            case 'crd':
                return this.findCard(product.creditCardId)
            default:
                return null
        }
    }

    dropdownMenu = product => {
        const {classes} = this.props;
        const achEnabled = product.achId > 0 || product.creditCardId > 0;
        const payment = this.findPaymentMethod(product)
        return (
            <Paper className={classes.dropdownMenu}>
                {achEnabled && (<Typography variant="title" color="inherit" className={classes.flex}>
                    Bank account ending in {payment.maskedNumber || payment.maskedAccount}
                </Typography>)}
                <Button 
                    className={classes.editButton}
                    href={`/subscription/${product.organizationId}/${product.id}/autopay`}>
                    {achEnabled ? 'edit' : 'add'}
                </Button>   
                {achEnabled && (<Button
                    className={classes.cancelButton}
                    aria-haspopup="false"
                    onClick={this.toggleModal}
                    color="inherit">
                    Cancel
                </Button>)}
            </Paper>
        )
    }

    renderChips = (product, classes) => {
        const lineItems = product.lineItems.filter(item => item.type !== 'discount')
        const chips = this.state.drawerOpen 
            ? lineItems
            : lineItems.filter((item, i) => this.chipLimit > i)
        return chips.map((chip, i) => (
            <Chip key={i} label={chip.name} className={classes} />
        ))    
    }

    paymentStatus = product => {
        const achEnabled = product.achId > 0 || product.creditCardId > 0;
        const statusText = achEnabled ? 'Enabled' : 'Disabled'
        const statusColor = achEnabled ? lightGreen[500] : grey[500]
        return (
            <p style={{color:statusColor}}>{statusText}</p>
        )
    }

    render() {
        const {classes, product, setAutoPay} = this.props;
        const {menuOpen, drawerOpen, modalOpen} = this.state;
        return (
            <>
            <AutoPayModal 
                onClose={this.closeModal}  
                product={product}
                setAutoPay={setAutoPay}          
                open={modalOpen}/>
            <Card className={classNames(classes.card, "mt-24 mb-24 ml-24")}>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar className={classes.toolBar}>
                        <div>
                            <Typography variant="title" color="inherit" className={classes.flex}>
                                Subscription {product.id} <small className={classes.nickName}>{product.nickname}</small>
                            </Typography>
                            {product.subscriptionExpires.length > 0 && (
                                <Typography variant="subheading" color="inherit" className={classes.flex}>
                                    Subscription Ends On: {product.subscriptionExpires}
                                </Typography>   
                            )}
                            {product.subscriptionExpires.length == 0 && (
                                <Typography variant="subheading" color="inherit" className={classes.flex}>
                                    Next Invoice Date: {product.nextBillDate}
                                </Typography>   
                            )}
                        </div>
                        <Button
                            className={classes.buttonLabel}
                            aria-owns={menuOpen ? 'menu-appbar' : null}
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            classes={{
                                label:'button-label'
                            }}
                            color="inherit">
	                        <Icon className="text-32 mr-12">timer</Icon>
                            <div>
                                <div>Autopay Status</div>
                                {this.paymentStatus(product)}
                            </div>
                            {menuOpen && (<Icon className="text-32 mr-12">expand_less</Icon>)}
                            {!menuOpen && (<Icon className="text-32 mr-12">expand_more</Icon>)}                            
                        </Button>
                    </Toolbar>
                </AppBar>
                <CardContent className={classes.cardContent}>
                    {menuOpen && this.dropdownMenu(product)} 
                    {this.renderChips(product, classes.chip)}
                </CardContent>
                {!drawerOpen && product.lineItems.length > this.chipLimit && (
                    <Button
                        aria-owns={menuOpen ? 'menu-appbar' : null}
                        aria-haspopup="false"
                        className={classes.drawerButton}
                        onClick={this.handleDrawer}
                        color="inherit">
                        View All Products
                        {drawerOpen && (<Icon className="text-32 mr-12">expand_less</Icon>)}
                        {!drawerOpen&& (<Icon className="text-32 mr-12">expand_more</Icon>)}                           
                    </Button> 
                )}                
            </Card>
            </>
        )
    }
}
export default withStyles(styles, {withTheme: true})(Product)