import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';

import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, Button, Card, CardContent, Icon, Toolbar, Typography} from '@material-ui/core';

import * as InvoiceActions from "../invoices/store/actions";

const styles = theme => ({
	appBar: {
		backgroundColor: "#187DC3"
	},
	balanceDue: {
		fontSize: 30
	},
    button: {
    	backgroundColor: '#187DC3'
    },
    card: {
    	flexBasis: "40%",
    	margin: 20
    },
    cardContent: {
    	paddingLeft: 70
    }
});

class WelcomeBalanceDue extends Component {

	componentDidMount() {
		if(this.props.orgId !== undefined && this.props.orgId >0) {
			this.props.getBalance(this.props.orgId);
		}
		
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

	}

	render()
	{
		const {classes} = this.props;

		return (
			<Card className={classNames(classes.card, "")}>

				<AppBar
                    position="static"
                    className={classes.appBar}
                >
                    <Toolbar>
                        <Icon className="text-32">attach_money</Icon>
                        <Typography variant="headline" color="inherit">
                            Balance Due
                        </Typography>
                    </Toolbar>
                </AppBar>

				<CardContent className={classes.cardContent}>

					<Typography variant="headline" color="inherit" className={classes.balanceDue}>
                        <b>${this.props.balance}</b>
                    </Typography>
                    <Button
                    	type="submit"
	                    component={NavLink}
	                    to={'/invoices'}
	                    className={classNames(classes.button, "normal-case mt-24 mb-24 max-w-192")}
	                    variant="raised"
	                    color="secondary"
	                    aria-label="add"
	                >
	                    MAKE A PAYMENT
	                </Button>

				</CardContent>

			</Card>
		)
	}
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getBalance: InvoiceActions.getBalance,
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        balance: state.invoices.invoices.balance,
		orgId: state.auth.user.data.orgId
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(WelcomeBalanceDue)));
