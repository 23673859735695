import Organization from 'main/content/organization/Organization';
import authRoles from 'auth/authRoles';

export const OrganizationConfig = {
	auth: authRoles.superadmin,
	routes: [
		{
			path: '/organization',
			component: Organization
		},
	]
};