import * as Actions from '../actions';
import {keyBy} from 'lodash'

const initialState = {};

const subscriptionsReducer = function(state = initialState, action)
{
	switch ( action.type )
	{
		/* 
		GET ALL SUBSCRIPTIONS
		This currently gets all subscriptions. The idea is to grab only the subscription that
		the specific organization has. This, I assume, would be done by checking the signed in user
		and finding the organization they belong to and then matching that with the field `organizationId`
		in the subscription.
		*/
		case Actions.GET_SUBSCRIPTIONS:
		{
			return {
				...state,
				...keyBy(action.payload, item => item.id)
			}
		}

		case Actions.GET_SUBSCRIPTION:
		{
			return {
				...state,
				[action.subscriptionId]: action.payload
			}
		}		

		case Actions.UPDATE_AUTOPAY_DATA:
		{
			return {
				...state,
				[action.subscriptionId]: Object.assign({}, state[action.subscriptionId], action.payload)
			}
		}
		default:
		{
			return state;
		}
	}
}

export default subscriptionsReducer;