import * as Actions from '../actions';

const initialState = {
    entities            : [],
    routeParams         : {}
};

const organizationReducer = function (state = initialState, action) {
    switch (action.type)
    {
        case Actions.GET_ORGANIZATION_INFO:
        {
            return {
                ...state,
                /*
                Since we're only interested in returning one organization we only
                need to set entities to action.payload. In all the other cases you
                will notice we use lodash's keyBy function to set entities. In this
                case it will mess everything up since we only want one organization
                and that's the way it's written to return in the organization-db.js file.
                */
                entities    : action.payload,
                routeParams : action.routeParams
            };
        }
        default:
        {
            return state;
        }
    }
};



export default organizationReducer;