import * as Actions from '../actions';

const initialState = {
    userInfo: {},
};

const myUserReducer = function (state = initialState, action) {
    switch (action.type)
    {
        case Actions.GET_USER:
        {
            return {
                ...state,
                userInfo: action.payload
            };
        }
        case Actions.UPDATE_USER:
        {
            return {
                ...state,
                userInfo: action.payload
            };
        }
        default:
        {
            return {
                ...state
            };
        }
    }
};

export default myUserReducer;