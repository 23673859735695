import axios from 'axios/index';

export const GET_BANK_METHODS = '[BANK_METHODS] GET BANK METHODS';
export const OPEN_NEW_BANK_METHOD_DIALOG = '[BANK_METHODS] OPEN NEW BANK METHOD DIALOG';
export const CLOSE_NEW_BANK_METHOD_DIALOG = '[BANK_METHODS] CLOSE NEW BANK METHOD DIALOG';
export const OPEN_REMOVE_BANK_METHOD_DIALOG = '[BANK_METHODS] OPEN REMOVE BANK METHOD DIALOG';
export const CLOSE_REMOVE_BANK_METHOD_DIALOG = '[BANK_METHODS] CLOSE REMOVE BANK METHOD DIALOG';
export const ADD_BANK_METHOD = '[BANK_METHODS] ADD BANK METHOD';
export const REMOVE_BANK_METHOD = '[BANK_METHODS] REMOVE BANK METHOD';

//TODO:replace this with a map

function convertType(type) {
    let result = null;
    if(type === 'CHECKING') {
        result = 'chec';
    }
    else if(type === 'SAVINGS') {
        result = 'savi';
    }
    else if(type === 'BUSINESS') {
        result = 'busi';
    }
    return result;
}

export function getBankMethods(organizationId)
{

	const response = axios.get('/api/payment-method/ach', {
        params: {orgId: organizationId}
	});

	return (dispatch) =>
		response.then((response) =>
			dispatch({
				type: GET_BANK_METHODS,
				payload: response.data,
				organizationId
			})
		);
}

export function openNewBankMethodDialog()
{
    return {
        type: OPEN_NEW_BANK_METHOD_DIALOG
    }
}

export function closeNewBankMethodDialog()
{
    return {
        type: CLOSE_NEW_BANK_METHOD_DIALOG
    }
}

export function openRemoveBankMethodDialog(data)
{
    return {
        type: OPEN_REMOVE_BANK_METHOD_DIALOG,
        data
    }
}

export function closeRemoveBankMethodDialog()
{
    return {
        type: CLOSE_REMOVE_BANK_METHOD_DIALOG
    }
}

export function addBankMethod(bankInfo, organizationId)
{
    return (dispatch) => {
        const request = axios.post('/api/payment-method/ach', {
            "organizationId": organizationId,
            "bankAccountNumber": bankInfo.bankAccountNumber,
            "routingNumber": bankInfo.routingNumber,
            "type": convertType(bankInfo.type),
            "nameOnAccount": bankInfo.nameOnAccount
        });

        return request.then(() => {
            dispatch({
                type: ADD_BANK_METHOD
            })

            dispatch(getBankMethods(organizationId));
        }
        );
    };
}

export function removeBankMethod(bankMethodId, organizationId)
{
    return (dispatch, getState) => {

        const {organizationId} = getState().bankMethodsReducer.bankMethods;

        const request = axios.delete('/api/payment-method/ach/'+bankMethodId);

        return request.then((response) => {
            
                dispatch({
                    type: REMOVE_BANK_METHOD
                })

                dispatch(getBankMethods(organizationId));
            }
        );
    };
}