import {combineReducers} from 'redux';
import fuse from './fuse';
import auth from 'auth/store/reducers/index';
import quickPanel from 'main/quickPanel/store/reducers';
import userStore from 'main/content/users/store/reducers';
import creditMethodsReducer from 'main/content/payment-methods/store/reducers/index';
import bankMethodsReducer from 'main/content/payment-methods/store/reducers/index';
import invoices from 'main/content/invoices/store/reducers';
import autopay from 'main/content/autopay/store/reducers';
import myUserStore from 'main/content/myuser/store/reducers';
import subscriptionsReducer from 'main/content/subscriptions/store/reducers';
import organizationsStore from 'main/content/organization/store/reducers';
import welcomeStore from 'main/content/welcome/store/reducers';
import activationReducer from 'main/content/activate/store/reducers';

const rootReducer = combineReducers({
	fuse,
    auth,
    quickPanel,
    userStore,
    creditMethodsReducer,
    bankMethodsReducer,
    invoices,
    autopay,
    myUserStore,
    subscriptionsReducer,
    organizationsStore,
    welcomeStore,
    activationReducer,
});

export default rootReducer;