import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Dialog, DialogTitle, Button, FormHelperText} from '@material-ui/core';


const styles = theme => ({
    cancelButton: {
        backgroundColor: '#ccc',
        color: '#fff',
    },
    confirmButton: {
        // display: 'inline-block',
        // width:'40%',
        // float:'right'
    },
    actions: {
        display:'flex',
        flexDIrection: 'row',
        justifyContent: 'space-around',
        padding:'20px'

    }
})

function AutoPayModal({classes, onClose, open, product, setAutoPay}) {
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Are you sure you wish to cancel autopay?</DialogTitle>
            <div className={classes.actions}>
                <Button
                    className={classes.cancelButton}
                    variant="contained" 
                    color="primary"            
                    onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    className={classes.confirmButton}
                    variant="contained" 
                    color="secondary"              
                    onClick={()=> {
                        setAutoPay("inv-0", product.id)
                        onClose()
                    }}>
                    Confirm
                </Button>
            </div>
        </Dialog>      
    )
}

export default withStyles(styles, {withTheme: true})(AutoPayModal)
