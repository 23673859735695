import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import classNames from 'classnames';
import {TextFieldFormsy} from '@fuse';
import Formsy from 'formsy-react';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, Button, Card, CardContent, Grow, Icon, Toolbar, Typography} from '@material-ui/core';

import * as Actions from './store/actions';
//import * as SubscriptionActions from '../subscriptions/store/actions';

const styles = theme => ({
	appBar: {
		backgroundColor: "#187DC3"
	},
	activationTitle: {
		marginBottom: 10
	},
	activationTitleDiv: {
		backgroundColor: '#f7f7f7',
		marginLeft: 48,
		marginTop: 24,
		padding: 24,
	},
    button: {
        backgroundColor: '#187DC3',
        color: '#ffffff',
        marginLeft: 48,
        width: "12rem"
    },
    formFields: {
        width: '100%'
    },
    formIcons: {
        color: '#187DC3'
    },
    formItems: {
        display: 'flex',
        alignItems: 'center'
    },
    card: {
		minWidth: 275,
	},
});

class ActivateForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canSubmit: false,
			hasError: null,
			activationCode: null,
		}
	}

	handleError() {
		this.setState({ hasError: true });
	}

	disableButton = () => {
        console.info("Disable button called");
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        console.info("Enable button called.");
        if(this.form != null) {
            if (this.form.getModel().activationCode === '' || 
                this.form.getModel().activationCode === undefined) {
                this.form.enableValidations = true;
            }
        
            if (this.form.getModel().activationCode === '' || 
                this.form.getModel().activationCode === undefined) {
                this.setState({canSubmit: false});
            } else {
                this.setState({canSubmit: true});
            }
        }
    };

    resetForm = () => {
        document.getElementById("form").reset()
    };

    onSubmit = (model) => {
		const {postActivationCode, showActivationCode} = this.props;
		postActivationCode(model.activationCode, this.props.orgId)
			.then(() => {
				showActivationCode(true);
				this.resetForm();
            });
	};

	render()
	{
		const {classes} = this.props;
		const {canSubmit} = this.state;

		return (
			<div>
				<Card className={classNames(classes.card, "max-w-lg mt-24 mb-24 ml-24")}>
					<AppBar
	                    position="static"
	                    className={classNames(classes.appBar, "mb-24")}
	                >
	                    <Toolbar>
	                        <Typography variant="headline" color="inherit">
	                            Enter Code To Receive Your Activation Key
	                        </Typography>
	                    </Toolbar>
	                </AppBar>
                    <CardContent>
                        <Formsy
                            id="form"
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            ref={(form) => this.form = form}
                            className="flex flex-col justify-center"
                        >
                            <div className={classes.formItems}>
                                <Icon className={classes.formIcons}>code</Icon>
                                <TextFieldFormsy
                                    className={classNames(classes.formFields, "ml-24 mb-24")}
                                    type="text"
                                    name="activationCode"
                                    label="Enter Activation code"
                                    validations={{
                                        isLength: 5
                                    }}
                                    validationErrors={{
                                        isLength: 'Must be a 5 digit code'
                                    }}
                                    required
                                />
                            </div>
                            <Button
                                type="submit"
                                variant="raised"
                                className={classNames(classes.button, "max-w-192")}
                                aria-label="ACTIVATE"
                                disabled={!canSubmit}
                            >
                                ACTIVATE
                            </Button>
                            { this.props.showKey &&
                                <Grow in={true}>
                            		<div className={classes.activationTitleDiv}>
                            			<div style={{display: 'flex', alignItems: 'baseline'}}>
                                    		<Typography
	                                        	className={classes.activationTitle}
	                                        	variant="subheading"
		                                    >
		                                    	Your Activation Key:
		                                    </Typography>
		                                    <Typography
		                                    	style={{ marginLeft: 10 }}
	                                        	variant="headline"
	                                        >
												{this.props.activationKey}
	                                       	</Typography>
	                                    </div>
	                                    <div style={{display: 'flex', alignItems: 'baseline'}}>
	                                       	<Typography
	                                        	className={classes.activationTitle}
	                                        	variant="subheading"
		                                    >
		                                    	Subscription End Date:
		                                    </Typography>
		                                    <Typography
	                                    		style={{ marginLeft: 10 }}
	                                        	variant="headline"
	                                        >
												{this.props.activationDate}
	                                       	</Typography>
	                                    </div>
                                    </div>
                                </Grow>
                            }
                        </Formsy>
                    </CardContent>
				</Card>
			</div>
		)
	}
}

function mapDispatchToProps(dispatch)
{
	return bindActionCreators({
        postActivationCode: Actions.postActivationCode,
		showActivationCode: Actions.showActivationCode,
	}, dispatch);
}

function mapStateToProps(state)
{
	return {
		activationKey: state.activationReducer.activate.key,
		activationDate: formatDate(state.activationReducer.activate.date),
		showKey: state.activationReducer.activate.showKey,
		orgId: state.auth.user.data.orgId
	}
}

function formatDate(date) {
	if(date != null) {
		let ds = date.split("-");
		return ds[1] + "/" + ds[2] + "/" + ds[0];
	}
	return "";
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivateForm)));
