import React, { Component } from 'react';

import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, Button, Card, CardContent, Icon, Toolbar, Typography} from '@material-ui/core';

const styles = theme => ({
	appBar: {
        backgroundColor: '#187DC3'
    },
    avatar: {
    	backgroundColor: '#187DC3'
    },
    button : {
    	backgroundColor: '#187DC3'
    },
    card: {
		maxWidth: 400,
		marginLeft:"20px"
	},
});

class InvoicesMain extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
			showCTA: true,
		};
	}

	render()
	{
		/* VARIABLES */
		const {classes} = this.props;

		return (
			<div style={{display: (this.state.showCTA === true ? 'block' : 'none') }}>
				<Card className={classes.card}>

					<AppBar
	                    position="static"
	                    className={classes.appBar}
	                >
	                    <Toolbar>
	                        <Icon className="text-32 mr-12">timer</Icon>
	                        <Typography variant="title" color="inherit">
	                            Autopay
	                        </Typography>
	                    </Toolbar>
	                </AppBar>

					<CardContent>

	                	<Typography variant="title" className="mt-24">Did you know that you can set up Autopay? Would you like to set it up now?</Typography>

	                	<Button
							href="/products"
	                        variant="raised"
	                        color="secondary"
	                        className={classNames(classes.button, "normal-case mt-24 mb-24 max-w-160")}
	                        aria-label="YES, THANKS!"
	                    >
	                    	YES, THANKS!
	                    </Button>

	                    <Button
	                        type="submit"
	                        variant="raised"
	                        color="secondary"
	                        className={classNames(classes.button, "normal-case mt-24 mb-24 max-w-160")}
	                        aria-label="NO, THANK YOU."
	                        onClick={() => {
	                        	this.setState({showCTA: false})
	                        }}
	                    >
	                    	NO, THANK YOU.
	                    </Button>

	                </CardContent>

	            </Card>
	        </div>
		)
	}
}

export default withStyles(styles, {withTheme: true})(InvoicesMain);