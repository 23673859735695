import axios from 'axios/index';
import { UPDATE_AUTOPAY_DATA } from '../../../subscriptions/store/actions';

export const GET_CREDIT_METHODS = '[PAYMENT_METHODS] GET CREDIT METHODS';
export const GET_BANK_METHODS = '[PAYMENT_METHODS] GET BANK METHODS';
export const GET_AUTOPAY_STATUS = '[PAYMENT_METHODS] GET AUTOPAY STATUS'
export const SET_AUTOPAY = '[AUTOPAY] SET AUTOPAY'
export const TOGGLE_IN_SELECTED_CREDIT_METHOD = '[PAYMENT_METHODS] TOGGLE IN SELECTED CREDIT METHODS';

export function getAutopayStatus(routeParams) {
	const request = axios.get('/api/subscription/?orgId=42', {
		params: routeParams
	});

	return (dispatch) =>
		request.then((response) => 
			dispatch({
				type: GET_AUTOPAY_STATUS,
				payload: response.data,
				routeParams
			})
		);
}

export function setAutopay(paymentType, subscriptionId) {
	let type = paymentType.substr(0,3)	
	let typeId = paymentType.substr(4)	
	let obj = {};

	switch(type) {
		case 'crd':
			obj = {
				paymentType: "creditcard",
				paymentMethodId: typeId,
				payMethod: "crd",
				achId: 0,
				creditCardId: +typeId
			}	
			break;
		case 'ach':
			obj = {
				paymentType: "ach",
				payMethod : "ach",
				paymentMethodId: typeId,
				achId: +typeId,
				creditCardId: 0
			}	
			break;			
		case 'inv': {
			obj = {
				paymentType: "invoice",
				payMethod : "invoice",
				achId: 0,
				creditCardId: 0
			}				
		}	
		default:
			obj = {
				paymentType: "invoice",
				payMethod : "invoice",
				achId: 0,
				creditCardId: 0
			}				
	}

	const request = axios.put('/api/subscription/'+subscriptionId+'/autopay',
		obj
	);



	return (dispatch) =>
		request.then(() => {
			if(obj.paymentType) delete obj.paymentType
			if(obj.paymentMethodId) delete obj.paymentMethodId			

			dispatch({
				type: UPDATE_AUTOPAY_DATA,
				payload: obj,
				subscriptionId				
			})
		}
		);
}

export function toggleInSelectedCreditMethod(creditMethodId)
{
	return {
		type: TOGGLE_IN_SELECTED_CREDIT_METHOD,
		creditMethodId
	}
}