import Products from './Products';
import authRoles from "../../../auth/authRoles";

export const ProductsConfig = {
    auth: authRoles.billing,
	routes: [
		{
			path: '/products',
			component: Products,
		}
	]
};
