import Example from './Example';

export const ExampleConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/example',
            component: Example
        }
    ]
};
