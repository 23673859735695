import React, { Component } from 'react';

import {FusePageSimple} from '@fuse';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";

import WelcomeHeader from './WelcomeHeader';
import WelcomeCustomerInfo from './WelcomeCustomerInfo';
import WelcomeBalanceDue from './WelcomeBalanceDue';
import WelcomeImportantNews from './WelcomeImportantNews';
import ErrorBoundary from '../errors/ErrorBoundary';

const styles = theme => ({
	components: {
		display: 'flex'
	}
})

const ROLE = 'ROLE_ADMIN';

const newState = {
	role: ROLE,
};

class Welcome extends Component {

	state = {
		...newState
	};

	render()
	{

		const {classes, user} = this.props;

		return (

			<React.Fragment>
				<FusePageSimple
					header={
						<WelcomeHeader />
					}
					content={
						<div>
							<div className={classes.components}>
								<ErrorBoundary>
                                    {user.role !== 'superadmin' &&
                                        <WelcomeCustomerInfo/>
                                    }
								</ErrorBoundary>
								
								{(user.role === 'admin' || user.role === 'billing') &&
									<WelcomeBalanceDue />
								}
								<WelcomeImportantNews />
							</div>
					
						</div>
					}
				/>
			</React.Fragment>
		)
	}
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps({auth})
{
    return {
        user : auth.user
    }
}
export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Welcome)));