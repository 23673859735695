import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseUtils} from '@fuse/index';
import {ExampleConfig} from 'main/content/example/ExampleConfig';
import {UsersConfig} from 'main/content/users/UsersConfig';
import {LoginConfig} from 'main/content/auth/LoginConfig';
import {LogoutConfig} from 'main/content/auth/LogoutConfig';
import {PaymentMethodsConfig} from 'main/content/payment-methods/PaymentMethodsConfig';
import {InvoicesConfig} from 'main/content/invoices/InvoicesConfig';
import {AutopayConfig} from 'main/content/autopay/AutopayConfig';
import {MyUserConfig} from 'main/content/myuser/MyUserConfig';
import {SubscriptionsConfig} from 'main/content/subscriptions/SubscriptionsConfig';
import {OrganizationConfig} from 'main/content/organization/OrganizationConfig';
import {ContactConfig} from 'main/content/contact/ContactConfig';
import {WelcomeConfig} from 'main/content/welcome/WelcomeConfig';
import {ActivateConfig} from 'main/content/activate/ActivateConfig';
import {DocumentsConfig} from 'main/content/documents/DocumentsConfig';
import {ProductsConfig} from 'main/content/products/ProductsConfig';
import {ErrorConfig} from 'main/content/errors/ErrorConfig';

const routeConfigs = [
    ExampleConfig,
    UsersConfig,
    LoginConfig,
    LogoutConfig,
    PaymentMethodsConfig,
    InvoicesConfig,
    AutopayConfig,
    MyUserConfig,
    DocumentsConfig,    
    ProductsConfig,
    SubscriptionsConfig,
    OrganizationConfig,
    ContactConfig,
    WelcomeConfig,
    ActivateConfig,
    ErrorConfig,
];

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path     : '/',
        exact    : true,
        component: () => <Redirect to="/welcome"/>
    }
];
