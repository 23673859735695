import React, { Component } from 'react';

import {CheckboxFormsy, SelectFormsy} from '@fuse';
import {AppBar, Button, Card, CardContent, CardHeader, Chip, Icon, MenuItem, Toolbar, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Formsy from 'formsy-react';
import classNames from 'classnames';
import _ from 'lodash';


const styles = theme => ({
	appBar: {
		backgroundColor: "#187DC3"
	},
    avatar: {
		backgroundColor: "#187DC3",
	},
    button: {
    	margin: theme.spacing.unit,
    	backgroundColor: '#187DC3'
    },
    card: {
		minWidth: 275,
	},
    nickName: {
        marginLeft: '2rem',
        fontWeight: 300
    },	
    chip: {
        margin: theme.spacing.unit / 4,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 500,
        maxWidth: 2000,
    },
});

class Autopay extends Component {

	/* Start this component with props from the parent and a blank state */
	constructor(props)
	{
		super(props);
		this.state = {
			enabled: false,
			canSubmit: false,
			creditMethod: 'select',
			enableValidations: false,
			hasError: null
		};
	}

	handleError() {
		this.setState({ hasError: true });
	}

	disableButton = () => {
		this.setState({canSubmit: false});
	};

	enableButton = () => {
		if(!this.form) return

		if(this.form.getModel().accept === false) {
			this.form.enableValidations = true;
		}
		/*
		If the checkbox in the form, having the name attribute equaled to "accept", is false (unchecked)
		or the select menu, having the name attribute equaled to "selectCreditMethod", is equaled to
		the word "select" then the form cannot be submitted.
		*/ 
		if(this.form.getModel().accept !== true || this.form.getModel().selectCreditMethod === 'select') {
			this.setState({canSubmit: false});
		} else {
			this.setState({canSubmit: true});
		}
		
	};

	getFilteredArray = (entities) => {
        return Object.keys(entities).map((id) => entities[id]);
    };

    /* 
    Set the state from what's in the form submission.
    Also, we need to consider adding an action here to set this as the payment method that
    Chargeover uses for autopay. Then it should be disabled for deletion in the payment
    methods list. I'm just not sure what endpoint that should hit or what field would label
    it as primary method for autopay. Once that function is set you can place a catch that
    runs the `handleError()` function.
    */
    onSubmit = (model) => {
		this.props.setAutoPay(model.selectCreditMethod, this.props.subscription.id);
	}

    renderChips = (subscription, classes) => {
		return subscription.lineItems
			.filter((item, i) => item.type != 'discount')
			.map((item, i) => (
            <Chip key={i} label={item.name} className={classes} />
        ))    
	}
	
	paymentMethod = subscription => {
		let payMethodId = ""
		if(subscription.achId > 0) {
			payMethodId = `ach-${subscription.achId}`;
		} 
		
		if (subscription.creditCardId > 0) {
			payMethodId = `crd-${subscription.creditCardId}`;
		}	
		return payMethodId
	}

	render() {
		const {classes, creditMethods, bankMethods, subscription = {lineItems: []}} = this.props;
		const {stat, canSubmit} = this.state;

		const creditMethodsData = this.getFilteredArray(creditMethods);
		const bankMethodsData = this.getFilteredArray(bankMethods);
		const enabled = subscription.payMethod !== "invoice" ? true : false;
		const payMethodId = this.paymentMethod(subscription)

		return (
			<div>
				<Card className={classNames(classes.card, "max-w-xl mt-24 mb-24 ml-24")}>
					<AppBar
	                    position="static"
	                    className={classes.appBar}>
	                    <Toolbar>
	                        <Icon className="text-32 mr-12">timer</Icon>
	                        <Typography variant="title" color="inherit" className={classes.flex}>
	                            Autopay
	                        </Typography>
	                    </Toolbar>
	                </AppBar>

					<CardHeader
						style={{ marginTop: 24 }}
						subheader={enabled === false ? <b>Status: Not activated</b> : <b>Status: Activated</b>}
						title={`Subscription # ${subscription.id} - ${subscription.nickname}`}/>

					<CardContent>
						<Typography className="h2">
							{enabled === false ? 'To setup autopay choose a payment method below and click the "Activate Autopay" button.' : ''}
						</Typography>
						<Typography 
							style={{ display: (enabled === true ? 'block' : 'none') }} 
							className="mt-48" paragraph>
							Use the select menus below to change information as needed.
						</Typography>

						{this.renderChips(subscription, classes.chip)}

						<Formsy
							onValidSubmit={this.onSubmit}
							onValid={this.enableButton}
							onInvalid={this.disableButton}
							ref={(form) => this.form = form}
							className="flex flex-col justify-center mt-52">

							<SelectFormsy
								id="selectPaymentMethod"
								className="my-24"
								label={enabled === false ? "Choose payment method" : ""}
								value={payMethodId}
								name="selectCreditMethod"
								validationError="You must choose a valid payment method">

								{creditMethodsData.map(creditMethod => (
	                                <MenuItem
	                                    key={creditMethod.id}
	                                    value={'crd-' + creditMethod.id}
	                                >
	                                Credit card ending in {creditMethod.maskedNumber}
	                                </MenuItem>
	                            ))}
	                            {bankMethodsData.map(bankMethod => (
	                                <MenuItem
	                                    key={bankMethod.id}
	                                    value={'ach-' + bankMethod.id}
	                                >
	                                Bank account ending in {bankMethod.maskedAccount}
	                                </MenuItem>
								))}
							</SelectFormsy>

							<CheckboxFormsy
								/* CSS apparently doesn't work anymore... */
								style={{ visibility: (stat === true ? 'hidden' : 'visible')}}
		                        className=""
		                        name="accept"
								label="Yes, please use this payment method to process future payments automatically"
								value=""
								validations="isTrue"
								validationError={enabled === true ? "" : ""}/>

							<div>
			                    <Button
			                    	style={{ visibility: (enabled === true ? 'visible' : 'visible')}}
			                    	onClick={() => {
			                    		enabled === false ? this.setState({enabled: false}) && this.reset() : this.setState({enabled: true})
			                    	}}
			                        type="submit"
			                        variant="raised"
			                        color="secondary"
			                        className={classNames(classes.button, "normal-case mt-24 mb-24 max-w-192")}
			                        aria-label="ACTIVATE AUTOPAY"
			                        disabled={!canSubmit}
			                    >
			                        {enabled === false ? 'ACTIVATE AUTOPAY' : 'UPDATE AUTOPAY'}
			                    </Button>

			                    <Button
			                    	style={{ visibility: (enabled === true ? 'visible' : 'hidden')}}
			                    	onClick={() => {
			                    		this.props.setAutoPay("inv-0", this.props.subscription.id);

										this.setState({
											enabled: false
										});
										/* Clear the form select menu on disable */
										this.form.reset()
			                    	}}
			                        type="submit"
			                        variant="raised"
			                        color="secondary"
			                        className={classNames(classes.button, "normal-case mt-24 mb-24 ml-24 max-w-160")}
			                        aria-label="SAVE AUTOPAY"
			                    >
			                        DISABLE AUTOPAY
			                    </Button>
								<Button
									variant="raised"
									color="secondary"								
									aria-label="Back to subscriptions"
									href="/products">
									Back To Subscriptions
								</Button>
			                </div>
						</Formsy>
					</CardContent>
				</Card>
			</div>
		)
	}
}

export default withStyles(styles, {withTheme: true})(Autopay);