import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import {AppBar, Button, Card, CardContent, Icon, MenuItem, Toolbar, Typography} from '@material-ui/core';
import {SelectFormsy, TextFieldFormsy, FusePageSimple} from '@fuse';

import Formsy from 'formsy-react';
import _ from 'lodash';
import classNames from 'classnames';

import * as Actions from './store/actions';
import UsersHeader from './UsersHeader';

const styles = theme => ({
    appBar: {
        backgroundColor: '#187DC3'
    },
    card: {},
    formFields: {
        width: '100%'
    },
    button: {
        backgroundColor: '#187DC3',
        color: '#ffffff',
        margin: theme.spacing.unit,
        width: "12rem"
    },
    formIcons: {
        color: '#187DC3'
    },
    formItems: {
        display: 'flex',
        alignItems: 'center'
    },
    inviteInputs: {
        marginBottom: 24
    },
    layoutRoot: {},
});

const newInviteState = {
    organizationId: 0,
    isExpired: false,
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    expiryDate: '',
    invitationCode: '',
    lastUpdate: new Date(),
};

class UsersInvite extends Component {

    state = {
        ...newInviteState,
        didInvite: false,
        duplicateInvite: false,
        canSubmit: false,
        enableValidations: false,
    };

    componentDidMount()
    {
        this.props.getRoles(this.props.orgId);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
            this.props.getInvites(this.props.match.params);
        }
    }

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        if(this.form != null) {
            if (this.form.getModel().email === '' || 
                this.form.getModel().email === undefined ||
                this.form.getModel().role === '' ||
                this.form.getModel().role === undefined) {
                this.form.enableValidations = true;
            }
        
            if (this.form.getModel().email === '' || 
                this.form.getModel().email === undefined ||
                this.form.getModel().role === '' ||
                this.form.getModel().role === undefined) {
                this.setState({canSubmit: false});
            } else {
                this.setState({canSubmit: true});
            }
        }
    };

    resetForm = () => {
        document.getElementById("form").reset()
    };

    /*
    This is not hit on page load
    */
    onSubmit = (model) => {
        const {inviteUser} = this.props;

        this.setState({
            emailAddress: model.email
        });

        inviteUser({
            firstName: model.firstName,
            lastName: model.lastName,
            emailAddress: model.email,
            role: model.role,
            organizationId: this.props.orgId,
        })
        .then((response) => {
            console.log("Tried to invite user:");
            console.log(response);
            this.setState({
                didInvite: true,
                duplicateInvite: false
             });
        })
        .catch(() => {
            this.setState({
                didInvite: false,
                duplicateInvite: true
            })
        })
        .finally(() => {
            this.resetForm();
        });
    };

    getFilteredArray = (entities) => {
        return Object.keys(entities).map((id) => entities[id]);
    };

    render()
    {
        const {classes, roles} = this.props;
        const {canSubmit, didInvite, duplicateInvite} = this.state;

        const rolesData = this.getFilteredArray(roles);

        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                    <UsersHeader/>
                }
                content={
                    <div className="max-w-lg mt-24 mb-24 ml-24">
                        <Card className={classes.card}>

                            <AppBar
                                position="static"
                                className={classes.appBar}
                            >
                                <Toolbar>
                                    <Icon className="text-32 mr-12">person</Icon>
                                    <Typography variant="title" color="inherit">
                                        Invite New User
                                    </Typography>
                                </Toolbar>
                            </AppBar>

                            <CardContent>
                                <Formsy
                                    id="form"
                                    onValidSubmit={this.onSubmit}
                                    onValid={this.enableButton}
                                    onInvalid={this.disableButton}
                                    ref={(form) => this.form = form}
                                    className="flex flex-col justify-center mt-16"
                                >
                                    <div className={classes.formItems}>
                                        <Icon className={classes.formIcons}>account_circle</Icon>
                                        <TextFieldFormsy
                                            className={classNames(classes.formFields, "ml-24 mb-24")}
                                            type="text"
                                            name="firstName"
                                            label="First Name"
                                            validations={{
                                                minLength: 2
                                            }}
                                            validationErrors={{
                                                minLength: 'Min character length is 2'
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className={classes.formItems}>
                                        <Icon className={classes.formIcons}>account_circle</Icon>
                                        <TextFieldFormsy
                                            className={classNames(classes.formFields, "ml-24 mb-24")}
                                            type="text"
                                            name="lastName"
                                            label="Last Name"
                                            validations={{
                                                minLength: 2
                                            }}
                                            validationErrors={{
                                                minLength: 'Min character length is 2'
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className={classes.formItems}>
                                        <Icon className={classes.formIcons}>email</Icon>
                                        <TextFieldFormsy
                                            className={classNames(classes.formFields, "ml-24 mb-24")}
                                            type="text"
                                            name="email"
                                            label="Email"
                                            validations="isEmail"
                                            validationError="This is not a valid email"
                                            required
                                        />
                                    </div>
                                                       
                                    <div className={classes.formItems}>
                                        <Icon className={classes.formIcons}>security</Icon>
                                        <SelectFormsy
                                            id="role"
                                            className={classNames(classes.formFields, "ml-24 mb-24")}
                                            label="Choose a role"
                                            value={this.state.role !== undefined ? this.state.role : ''}
                                            name="role"
                                            required
                                        >
                                            {rolesData.map(role => (
                                              <MenuItem
                                                key={role.id}
                                                value={role['name']}
                                                >
                                                {role['name']}
                                              </MenuItem>
                                            ))}
                                        </SelectFormsy>
                                    </div>
                                    <div className={classes.formItems}>
                                        <Button
                                            type="submit"
                                            variant="raised"
                                            className={classNames(classes.button, "max-w-192")}
                                            aria-label="SEND INVITE"
                                            disabled={!canSubmit}
                                        >
                                            SEND INVITE
                                        </Button>
                                        <Button
                                            style={{width: "14rem"}}
                                            type="submit"
                                            variant="raised"
                                            className={classes.button}
                                            aria-label="BACK TO LIST"
                                            component={NavLink}
                                            to={'/users/list'}
                                        >
                                            BACK TO LIST
                                        </Button>
                                    </div>
                                </Formsy>
                                <Typography variant="headline" className="mt-24" style={{ display: (didInvite === true ? 'block' : 'none')}}>Your invite has been sent to <b>{this.state.emailAddress}</b>.</Typography>
                                <Typography variant="headline" className="mt-24" style={{ display: (duplicateInvite === true ? 'block' : 'none')}}><b>{this.state.emailAddress}</b> has already been invited.</Typography>
                            </CardContent>
                        </Card>
                    </div>
                }
            />
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        inviteUser: Actions.inviteUser,
        getInvites: Actions.getInvites,
        getRoles: Actions.getRoles,
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        users: state.userStore.users.entities,
        roles: state.userStore.users.roles,
        orgId: state.auth.user.data.orgId,
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersInvite)));