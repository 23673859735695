import axios from 'axios/index';

export const GET_CREDIT_METHODS = '[CREDIT_METHODS] GET CREDIT METHODS';
export const OPEN_NEW_CREDIT_METHOD_DIALOG = '[CREDIT_METHODS] OPEN NEW CREDIT METHOD DIALOG';
export const CLOSE_NEW_CREDIT_METHOD_DIALOG = '[CREDIT_METHODS] CLOSE NEW CREDIT METHOD DIALOG';
export const OPEN_REMOVE_CREDIT_METHOD_DIALOG = '[CREDIT_METHODS] OPEN REMOVE CREDIT METHOD DIALOG';
export const CLOSE_REMOVE_CREDIT_METHOD_DIALOG = '[CREDIT_METHODS] CLOSE REMOVE CREDIT METHOD DIALOG';
export const ADD_CREDIT_METHOD = '[CREDIT_METHODS] ADD CREDIT METHOD';
export const REMOVE_CREDIT_METHOD = '[CREDIT_METHODS] REMOVE CREDIT METHOD';

export function getCreditMethods(organizationId)
{
	const response = axios.get('/api/payment-method/creditcard', {
		params: {orgId: organizationId}
	});

	return (dispatch) =>
		response.then((response) =>
			dispatch({
				type: GET_CREDIT_METHODS,
				payload: response.data,
				organizationId
			})
		);
}

export function openNewCreditMethodDialog()
{
    return {
        type: OPEN_NEW_CREDIT_METHOD_DIALOG
    }
}

export function closeNewCreditMethodDialog()
{
    return {
        type: CLOSE_NEW_CREDIT_METHOD_DIALOG
    }
}

export function openRemoveCreditMethodDialog(data)
{
    return {
        type: OPEN_REMOVE_CREDIT_METHOD_DIALOG,
        data
    }
}

export function closeRemoveCreditMethodDialog()
{
    return {
        type: CLOSE_REMOVE_CREDIT_METHOD_DIALOG
    }
}

export function addCreditMethod(model, organizationId)
{
    return (dispatch) => {
        const request = axios.post('/api/payment-method/creditcard', {
            "organizationId": organizationId,
            "nameOnCard": model.nameOnCard,
            "cardNumber": model.cardNumber,
            "cvv": model.cvv,
            "expMonth": model.expMonth,
            "expYear": model.expYear,
            "address": model.address,
            "city": model.city,
            "state": model.state,
            "postCode": model.postalCode,
            "country": model.country
            });

        return request.then(() => {
            dispatch({
                type: ADD_CREDIT_METHOD
            })
            
            dispatch(getCreditMethods(organizationId));
        }
        );
    };
}

export function removeCreditMethod(creditCardId, organizationId)
{
    return (dispatch) => {

        console.log("Remove credit card: " + creditCardId)

        const request = axios.delete('/api/payment-method/creditcard/'+creditCardId);

        return request.then((response) => {

            
            dispatch({
                    type: REMOVE_CREDIT_METHOD,
                    creditCardId
                }
            )

            dispatch(getCreditMethods(organizationId));
            }
        );

    };
}