import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Paper, Typography} from '@material-ui/core'
import {FusePageSimple} from '@fuse';
import {S3, CognitoIdentityCredentials} from 'aws-sdk';
import DocumentsHeader from './DocumentsHeader'
import DocxLogo from '../../../icons/noun_DOC_File_237046';
import PdfLogo from '../../../icons/noun_PDF_440076';
import DownloadLogo from '../../../icons/noun_Download_711090';
import DocLogo from '../../../icons/noun_Document_4090';

const apiVersion = '2006-03-01';
const Bucket = process.env.REACT_APP_AWS_DOC_BUCKET;
const Prefix = 'public/'
const region = 'us-east-2';
const IdentityPoolId = 'us-east-2:1fafedb7-f1ba-440c-89fb-c7792f9606eb';

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing.unit * 8,
    },
    paper: {
        height:'128px',
        width:'128px',
        position:'relative',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center;'
    },
    link: {
        width:'30px',
        height:'30px',
        position:'absolute',
        bottom: '10px',
        right: '10px'
    },
    svgDocIcon: {
        fill:'gray',
        width: '50%'
    },
    svgDownloadIcon: {
        width:'100%',
        height: 'auto',
        fill: '#187DC3'
    },
    item: {
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    }
  });


class Documents extends Component {

    s3 = null

    state = {
        documents: []
    }

    loadDocuments = async () => {
        try {
            const {Contents} = await this.s3.listObjectsV2({Bucket, Prefix}).promise();
            const documents = Contents.filter(doc => doc.Size).map(this.setDocumentProps)
            this.setState({
                documents
            })
        }
        catch(e) {
            console.error(e)
        }
    }

    setDocumentProps = doc => {
        this.setDocumentExtension(doc);
        this.setDocPath(doc)
        this.setDocName(doc)
        return doc;
    }

    setDocPath = doc => {
        const Expires = 3600;
        const path = this.s3.getSignedUrl('getObject', {Bucket, Expires, Key:doc.Key});
        doc.Path = path;
    }

    setDocName = doc => {
        const name = doc.Key.split('/')[1];
        doc.Name = name;
    }

    setDocumentExtension = doc => {
        const pieces = doc.Key.split('.');
        const ext = pieces[pieces.length - 1]
        doc.Ext = ext;
    }

    renderIcon = doc => {
        const {classes} = this.props;
        switch(doc.Ext) {
            case 'pdf':
                return (<PdfLogo className={classes.svgDocIcon} />)
            case 'docx':
                return (<DocxLogo className={classes.svgDocIcon} />)
            default:
                return (<DocLogo className={classes.svgDocIcon}/>)
        }
    }

    renderDocument = (doc, key) => {
        const {classes} = this.props;
        return (
            <Grid className={classes.item} key={key} item>
                <Paper className={classes.paper}>
                    {this.renderIcon(doc)}
                    <a className={classes.link} href={doc.Path} download={doc.Name}><DownloadLogo className={classes.svgDownloadIcon}/></a>
                </Paper>
                <p>{doc.Name}</p>
            </Grid>            
        )
    }

    render() {
        const {documents} = this.state;
        const {classes} = this.props;
        return (
            <FusePageSimple
                header={
                    <DocumentsHeader />
                }
                content={
                    <div className={classes.root}>
                        <Grid container spacing={8}>
                            {documents.map(this.renderDocument)}
                        </Grid>
                    </div>
                }
            />
        )
    }

    componentDidMount() {
        this.loadDocuments();
    }    

    constructor(props) {
        super(props)
        const credentials = new CognitoIdentityCredentials({IdentityPoolId},{region});
        this.s3 = new S3({apiVersion, region, credentials})
    }    
}

export default withStyles(styles)(Documents)
