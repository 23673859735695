import Subscriptions from './Subscriptions';

export const SubscriptionsConfig = {
    routes  : [
        {
            path     : '/subscription',
            component: Subscriptions
        }
    ]
};
