import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

import {withStyles} from '@material-ui/core/styles/index';
import Formsy, {addValidationRule} from 'formsy-react';
import {Button, Card, CardContent, FormControl, Grow, Input, InputLabel, Typography} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';

import * as Actions from './store/actions';
import * as AuthActions from '../../../auth/store/actions';
import TextFieldFormsy from "../../../@fuse/components/formsy/TextFieldFormsy";

const styles = theme => ({
    root: {
        background    : "url('/assets/images/backgrounds/jst_bg.png') no-repeat",
        backgroundSize: 'cover'
    },
    card: {
        width   : '100%',
        maxWidth: 384
    },
    button: {
        backgroundColor: '#187DC3'
    }
});

const newState = {
    firstName: '',
    lastName: '',
    username: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
};

addValidationRule('meetsPasswordComplexity', (values, value) => {
    let passwordIsLongEnough = value.length >= 10;

    let specialChars = /[`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    let lowercase = /[a-z]/;
    let uppercase = /[A-Z]/;
    let digit = /[0-9]/;

    let hasSpecialCharacter = specialChars.test(value);
    let hasLowerCaseLetter = lowercase.test(value);
    let hasUpperCaseLetter = uppercase.test(value);
    let hasDigit = digit.test(value);

    return passwordIsLongEnough && hasSpecialCharacter && hasLowerCaseLetter && hasUpperCaseLetter && hasDigit;
});

addValidationRule('isEqual', (values, value, secondField) => {
    return value === values[secondField]
});


class UserRegistration extends Component {
    state = {
        ...newState,
        invitationCode: '',
        inviteId: 'b9100f0f',
        canSubmit: false,
    };

    componentDidMount()
    {
        this.mounted = true;

        this.props.validateInvite(window.location.href.split('/').pop()).then((response => {
            if (this.mounted) {
                this.setState({
                    invitationCode: window.location.href.split('/').pop(),
                    firstName: this.props.invite.firstName,
                    lastName: this.props.invite.lastName,
                    username: this.props.invite.emailAddress,
                    validInvite: true
                });
            }
        }),
        (error) => {
            console.log("No invite for j00")
            this.setState({
                validInvite:false

            })
        });
    }

    componentWillUnmount(){
        // Prevents memory leak
        this.mounted = false;
    }

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onSubmit = (model) => {
        this.props.acceptInvitation({
            firstName: model.firstName,
            lastName: model.lastName,
            emailAddress: model.username,
            phoneNumber: model.phoneNumber,
            password: model.password,
            invitationCode: this.props.invite.invitationCode,
        }).then(() => {
            console.log("Logging out after accepting invite");
            this.props.logoutUser();
        });
    };

    render()
    {
        const {classes} = this.props;
        const {firstName, lastName, username, phoneNumber, password, confirmPassword} = this.state;

        return (
            <div className={classNames(classes.root, "flex flex-col flex-auto flex-no-shrink items-center justify-center p-32")}>

                <div className="flex flex-col items-center justify-center w-full">
                    <Grow in={true}>
                        <Card className={classes.card}>

                            <CardContent className="flex flex-col items-center justify-center p-32">

                                <img className="w-128 m-32" src="assets/images/logos/jst_logo.png" alt="logo"/>

                                
                                {this.state.validInvite === false ?
                                    <Typography variant="title" className="mt-16 text-red">That invite code is invalid, expired, or has already been consumed.</Typography>
                                :
                                <div>
                                <Typography variant="title" className="mt-16">WELCOME, {this.state.firstName.toUpperCase()}</Typography>
                                <Typography variant="title" className="mt-16 mb-32">COMPLETE THE FORM TO LOGIN</Typography>
                                    <Formsy
                                        id="form"
                                        ref={(form) => this.form = form}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        onValidSubmit={this.onSubmit}
                                        className="flex flex-col justify-center w-full"
                                    >
                                        <TextFieldFormsy
                                            className="mb-16"
                                            required
                                            fullWidth
                                            label="First name"
                                            type="text"
                                            name="firstName"
                                            value={firstName}
                                            onChange={this.handleChange}
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            required
                                            fullWidth
                                            label="Last name"
                                            type="text"
                                            name="lastName"
                                            value={lastName}
                                            onChange={this.handleChange}
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            required
                                            fullWidth
                                            label="Username"
                                            type="text"
                                            name="username"
                                            value={username}
                                            onChange={this.handleChange}
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            required
                                            fullWidth
                                            label="Phone Number"
                                            type="text"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={this.handleChange}
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            required
                                            fullWidth
                                            label="Password"
                                            type="password"
                                            name="password"
                                            value={password}
                                            validations="meetsPasswordComplexity"
                                            validationError="Required: 10+ characters with 1+ lowercase, 1+ uppercase, 1+ number, and 1+ special character"
                                            onChange={this.handleChange}
                                        />
                                        <TextFieldFormsy
                                            className="mb-16"
                                            required
                                            fullWidth
                                            label="Confirm Password"
                                            type="password"
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            validations="isEqual:password"
                                            validationError="Passwords must be equal"
                                            onChange={this.handleChange}
                                        />
                                        <Button
                                            type="submit"
                                            variant="raised"
                                            color="primary"
                                            className={classNames(classes.button, "w-224 mx-auto mt-16")}
                                            aria-label="LOG IN"
                                            disabled={!this.state.canSubmit}
                                        >
                                        Register
                                        </Button>
                                    </Formsy>
                                    </div>
                                }
                            </CardContent>
                        </Card>
                    </Grow>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getInvites: Actions.getInvites,
        validateInvite: Actions.validateInvite,
        acceptInvitation: Actions.acceptInvitation,
        logoutUser: AuthActions.logoutUser,
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        newUser: state.userStore.users.entities,
        //invites: state.userStore.users.invites,
        invite: state.userStore.users.invite,
    }
}

export default withStyles(styles, {withTheme: true}) (withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRegistration)));