import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {Button, Card, CardContent, Grow, Typography} from '@material-ui/core';
import classNames from 'classnames';
import {Link, withRouter} from 'react-router-dom';
import _ from 'lodash';
import Formsy from 'formsy-react';
import TextFieldFormsy from "../../../@fuse/components/formsy/TextFieldFormsy";
import * as Actions from "../../../auth/store/actions";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";

const styles = theme => ({
    root: {
        background    : "url('/assets/images/backgrounds/jst_bg.png') no-repeat",
        backgroundSize: 'cover'
    },
    card: {
        width   : '100%',
        maxWidth: 384
    }
});

class ForgotPasswordPage extends Component {
    state = {
        email: '',
        canSubmit: false
    };

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onSubmit = (model) => {
        this.props.resetPassword(model);
    };

    render()
    {
        const {classes} = this.props;
        const {email} = this.state;

        return (
            <div className={classNames(classes.root, "flex flex-col flex-auto flex-no-shrink items-center justify-center p-32")}>

                <div className="flex flex-col items-center justify-center w-full">

                    <Grow in={true}>
                        <Card className={classes.card}>

                            <CardContent className="flex flex-col items-center justify-center p-32">

                                <div className="w-128 m-32">
                                    <img src="assets/images/logos/jst_logo.png" alt="logo"/>
                                </div>

                                <Typography variant="title" className="mt-16 mb-32">PASSWORD RESET</Typography>
                                <Typography variant="body1" className="mt-16 mb-32">Please enter your email address to start the password reset process.</Typography>

                                    <Formsy
                                        onValidSubmit={this.onSubmit}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={(form) => this.form = form}
                                        className="flex flex-col justify-center w-full"
                                    >

                                    <TextFieldFormsy
                                        className="mb-16"
                                        label="Email"
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        validations="isEmail"
                                        validationError="Please enter a valid email address"
                                        fullWidth
                                        required
                                    />

                                    <Button
                                        type="submit"
                                        variant="raised"
                                        color="primary"
                                        className="w-224 mx-auto mt-16"
                                        aria-label="Reset Password"
                                        disabled={!this.state.canSubmit}
                                    >
                                        SEND RESET LINK
                                    </Button>

                                </Formsy>

                                <div className="flex flex-col items-center justify-center pt-32 pb-24">
                                    <Link className="font-medium" to="/login">Go back to login</Link>
                                </div>

                            </CardContent>
                        </Card>
                    </Grow>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        resetPassword: Actions.resetPassword,
    }, dispatch);
}

function mapStateToProps({auth})
{
    return {
        login: auth.login,
        user : auth.user,
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage)));
