import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, Grow, Input, InputLabel, Typography} from '@material-ui/core';
import classNames from 'classnames';
import {Link, withRouter} from 'react-router-dom';
import _ from 'lodash';
import Formsy from 'formsy-react';
import CheckboxFormsy from "../../../@fuse/components/formsy/CheckboxFormsy";
import TextFieldFormsy from "../../../@fuse/components/formsy/TextFieldFormsy";
import connect from "react-redux/es/connect/connect";
import * as Actions from '../../../auth/store/actions';
import {bindActionCreators} from "redux";

const styles = theme => ({
    root: {
        background    : "url('/assets/images/backgrounds/jst_bg.png') no-repeat",
        backgroundSize: 'cover'
    },
    card: {
        width   : '100%',
        maxWidth: 384
    }
});

class LoginPage extends Component {
    state = {
        email   : '',
        password: '',
        remember: true,
        canSubmit: false
    };

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    form = React.createRef();

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onSubmit = (model) => {
        this.props.submitLogin(model);
    };

    componentDidUpdate(prevProps, prevState)
    {
        if ( this.props.login.error && (this.props.login.error.email || this.props.login.error.password) )
        {
            this.form.updateInputsWithError({
                ...this.props.login.error
            });

            this.props.login.error = null;
            this.disableButton();
        }

        if ( this.props.user.role !== 'guest' )
        {
            const pathname = this.props.location.state && this.props.location.state.redirectUrl ? this.props.location.state.redirectUrl : '/';
            this.props.history.push({
                pathname
            });
        }
        return null;
    }


    /*canBeSubmitted()
    {
        const {email, password} = this.state;
        return (
            email.length > 0 && password.length > 0
        );
    }*/

    render()
    {
        const {classes} = this.props;
        const {email, password, remember, canSubmit} = this.state;

        return (
            <div className={classNames(classes.root, "flex flex-col flex-auto flex-no-shrink items-center justify-center p-32")}>

                <div className="flex flex-col items-center justify-center w-full">
                    <Grow in={true}>
                        <Card className={classes.card}>

                            <CardContent className="flex flex-col items-center justify-center p-32">

                                <img className="w-128 m-32" src="assets/images/logos/jst_logo.png" alt="logo"/>

                                <Typography variant="title" className="mt-16 mb-32">LOGIN TO YOUR ACCOUNT</Typography>

                                <Formsy
                                    onValidSubmit={this.onSubmit}
                                    onValid={this.enableButton}
                                    onInvalid={this.disableButton}
                                    ref={(form) => this.form = form}
                                    className="flex flex-col justify-center w-full"
                                >

                                    <TextFieldFormsy
                                        className="mb-16"
                                        type="email"
                                        name="email"
                                        label="Email"
                                        value={email}
                                        onChange={this.handleChange}
                                        required
                                    />

                                    <TextFieldFormsy
                                        className="mb-16"
                                        type="password"
                                        name="password"
                                        label="Password"
                                        value={password}
                                        onChange={this.handleChange}
                                        required
                                    />

                                    <Button
                                        type="submit"
                                        variant="raised"
                                        color="primary"
                                        className="w-224 mx-auto mt-16"
                                        aria-label="LOG IN"
                                        disabled={!canSubmit}
                                    >
                                        LOGIN
                                    </Button>

                                    <div className="flex flex-col items-center justify-center pt-32">
                                        <Link className="font-medium" to="/forgot-password">Forgot password</Link>
                                    </div>
                                </Formsy>

                            </CardContent>
                        </Card>
                    </Grow>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        submitLogin: Actions.submitLogin,
    }, dispatch);
}

function mapStateToProps({auth})
{
    return {
        login: auth.login,
        user : auth.user,
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage)));