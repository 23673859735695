/* React / Redux */
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


/* Node Modules */
import {withStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    Card,
    CardContent,
    FormControl,
    Grow,
    Icon,
    Input,
    InputLabel,
    Toolbar,
    Typography
} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';

/* Project Imports */
import * as Actions from './store/actions';
import Formsy from "formsy-react";
import TextFieldFormsy from "../../../@fuse/components/formsy/TextFieldFormsy";
import UsersHeader from "../users/UsersHeader";
import FusePageSimple from "../../../@fuse/components/FusePageLayouts/FusePageSimple";

/* Styles */
const styles = theme => ({
    appBar: {
        backgroundColor: '#187DC3'
    },
    card: {
        width: 700
    },
    formIcons: {
        color: '#187DC3'
    },
    formItems: {
        display: 'flex',
        alignItems: 'center'
    },
    formControl: {
        marginBottom: 24
    },
    formFields: {
        width: '100%'
    },
    updateButton: {
        backgroundColor: "#039be5",
        width: 224,
        marginTop: 16
    },
    layoutRoot: {},
});

/* Init State */
const newMyUserState = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phoneNumber: '',
};


class MyUserForm extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
		    ...newMyUserState,
           canSubmit: false,
        };
	}

	componentDidMount()
    {
         this.props.getUser(this.props.userId);
    }
    
    componentDidUpdate(prevProps, prevState)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {

        }
    }

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onSubmit = (model) => {
        console.log(model);
        this.props.updateUser({
            firstName: model.firstName,
            lastName: model.lastName,
            emailAddress: model.username,
            phoneNumber: model.phoneNumber,
            password: model.password,
            userId: this.props.userId,
        }).then(() => {
            this.props.history.push('/welcome');
        });
    };

	render()
	{
		const {classes, updateUser} = this.props;

		return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                content={
                    <div className="max-w-lg mt-24 mb-24 ml-24">
                                <Card className={classes.card}>
                                    <AppBar
                                        position="static"
                                        className={classes.appBar}
                                    >
                                        <Toolbar>
                                            <Icon className="text-32 mr-12">person</Icon>
                                            <Typography variant="title" color="inherit">
                                                Update My Profile
                                            </Typography>
                                        </Toolbar>
                                    </AppBar>

                                    <CardContent className="flex flex-col p-32">
                                        <Formsy
                                            id="form"
                                            ref={(form) => this.form = form}
                                            onValid={this.enableButton}
                                            onInvalid={this.disableButton}
                                            onValidSubmit={this.onSubmit}
                                            className="flex flex-col justify-center w-full"
                                        >

                                            <div className={classes.formItems}>
                                                <Icon className={classes.formIcons}>email</Icon>
                                                <TextFieldFormsy
                                                    className={classNames(classes.formFields, "ml-24 mb-24")}
                                                    required
                                                    fullWidth
                                                    label="Email"
                                                    type="email"
                                                    name="email"
                                                    value={this.props.email}
                                                    validations="isEmail"
                                                    validationError="Please enter a valid email address"
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            <div className={classes.formItems}>
                                                <Icon className={classes.formIcons}>account_circle</Icon>
                                                <TextFieldFormsy
                                                    className={classNames(classes.formFields, "ml-24 mb-24")}
                                                    required
                                                    fullWidth
                                                    label="First name"
                                                    type="text"
                                                    name="firstName"
                                                    value={this.props.firstName}
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            <div className={classes.formItems}>
                                                <Icon className={classes.formIcons}>account_circle</Icon>
                                                <TextFieldFormsy
                                                    className={classNames(classes.formFields, "ml-24 mb-24")}
                                                    required
                                                    fullWidth
                                                    label="Last name"
                                                    type="text"
                                                    name="lastName"
                                                    value={this.props.lastName}
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            <div className={classes.formItems}>
                                                <Icon className={classes.formIcons}>phone</Icon>
                                                <TextFieldFormsy
                                                    className={classNames(classes.formFields, "ml-24 mb-24")}
                                                    required
                                                    fullWidth
                                                    label="Phone Number"
                                                    type="text"
                                                    name="phoneNumber"
                                                    value={this.props.phoneNumber}
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            <div className={classes.formItems}>
                                                <Icon className={classes.formIcons}>security</Icon>
                                                <TextFieldFormsy
                                                    className={classNames(classes.formFields, "ml-24 mb-24")}
                                                    required
                                                    fullWidth
                                                    label="Password"
                                                    type="password"
                                                    name="password"
                                                    value={this.state.password}
                                                    validations="meetsPasswordComplexity"
                                                    validationError="Required: 10+ characters with 1+ lowercase, 1+ uppercase, 1+ number, and 1+ special character"
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            <div className={classes.formItems}>
                                                <Icon className={classes.formIcons}>security</Icon>
                                                <TextFieldFormsy
                                                    className={classNames(classes.formFields, "ml-24 mb-24")}
                                                    required
                                                    fullWidth
                                                    label="Confirm Password"
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={this.state.confirmPassword}
                                                    validations="isEqual:password"
                                                    validationError="Passwords must be equal"
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            <Button
                                                type="submit"
                                                variant="raised"
                                                color="primary"
                                                className={classes.updateButton}
                                                aria-label="UPDATE"
                                                /*disabled={!this.canBeSubmitted()}*/
                                            >
                                                UPDATE
                                            </Button>

                                        </Formsy>

                                    </CardContent>
                                </Card>
                    </div>
                }
                />
		);
	}
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
    	getUser: Actions.getUser,
    	updateUser: Actions.updateUser,
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
    	userId: state.auth.user.data.userId,
        email: state.myUserStore.users.userInfo.emailAddress,
        firstName: state.myUserStore.users.userInfo.firstName,
        lastName: state.myUserStore.users.userInfo.lastName,
        phoneNumber: state.myUserStore.users.userInfo.phoneNumber,
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyUserForm)));