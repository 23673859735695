/**
 * Authorization Roles
 */
const authRoles = {
    superadmin : ['superadmin'],
    admin    : ['admin'],
    billing    : ['admin', 'billing'],
    support     : ['admin', 'billing', 'support'],
    allUsers    : ['superadmin', 'admin', 'billing', 'support'],
    onlyGuest: ['guest']
};

export default authRoles;
