import axios from 'axios/index';
import {FuseUtils} from '@fuse';

export const GET_ORGANIZATIONS = '[ORGANIZATION STORE] GET ORGANIZATIONS';
export const CREATE_ORGANIZATION = '[ORGANIZATION STORE] CREATE ORGANIZATION';
export const INVITE_USER = '[ORGANIZATION STORE] INVITE USER';
export const GET_INVITES = '[ORGANIZATION STORE] GET INVITES';
export const GET_ORGANIZATION_INFO = '[ORGANIZATION STORE] GET ORGANIZATION INFO';

export function getOrganizationInfo(organizationId)
{
    const getOrganization = axios.get('/api/organization/' + organizationId);

    return (dispatch) =>
        getOrganization.then((response) =>
            dispatch({
                type   : GET_ORGANIZATION_INFO,
                payload: response.data,
            })
        );
}


export function getOrganizations(routeParams)
{
	const request = axios.get('/api/organization/', {
		params: routeParams
	});

	return (dispatch) =>
		request.then((response) =>
			dispatch({
				type: GET_ORGANIZATIONS,
				payload: response.data,
				routeParams
			})
		);
}

export function getInvites(routeParams)
{
    const request = axios.get('/api/invite/', {
        routeParams: routeParams
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_INVITES,
                payload: response.data,
                routeParams
            })
        );
}

export function createOrganization(organization)
{
    return (dispatch, getState) => {

        const request = axios.post('/api/organization/', {
            isActive: true,
            name: organization.name,
            address: organization.address,
            phoneNumber: organization.phoneNumber,
            adminFirstName: organization.adminFirstName,
            adminLastName: organization.adminLastName,
            adminEmail: organization.adminEmail,
            chargeOverId: organization.chargeOverId,
        });

        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: CREATE_ORGANIZATION,
                    payload: response.data
                })
            ])
        );
    };
}


export function createInvite(organizationAndAdmin, newOrganization)
{

    /* I'm just setting a date that's one year out */
    const now = new Date();
    const expDate = now.getFullYear() + 1;
    
    /*
    Set a new state to post to `/api/invite` to create a new invite with only the
    necessary information.
    */
    const newInvite = {
        organizationId: newOrganization.id,
        isActive: true,
        isExpired: false,
        firstName: organizationAndAdmin.rootAccount.adminFirst,
        lastName: organizationAndAdmin.rootAccount.adminLast,
        email: organizationAndAdmin.rootAccount.adminEmail,
        password: organizationAndAdmin.rootAccount.password,
        phoneNumber: organizationAndAdmin.rootAccount.phoneNumber,
        roles: organizationAndAdmin.rootAccount.roles,
        invitationCode: FuseUtils.generateGUID(),
        expiryDate: expDate.toString(),

    };

    return (dispatch, getState) => {

        /*
        It may not always be necessary to have a then() and dispatch an action to return the updated
        list of objects but this does help me see what's saving to global state using the Redux
        Chrome extension.
        */
        const {routeParams} = getState().organizationsStore.organization;
        
        /*
        Now we post the full state again but this time to the invites table with just the data defined
        in the variable `newInvite` shown above.
        */
        const request = axios.post('/api/invite/', {
            newInvite
        });


        return request.then((response) =>
            Promise.all([
                dispatch({
                    type: INVITE_USER
                })
            /*
            These two lines will return all organizations and invites.
            */
            ]).then(() => dispatch(getOrganizations(routeParams)))
            .then(() => dispatch(getInvites(routeParams)))
        );
    };
}