import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {Button, Card, CardContent, Grow, Typography} from '@material-ui/core';
import classNames from 'classnames';
import {Link, withRouter} from 'react-router-dom';
import Formsy, {addValidationRule} from "formsy-react";
import TextFieldFormsy from "../../../@fuse/components/formsy/TextFieldFormsy";
import _ from "lodash";
import {bindActionCreators} from "redux";
import * as Actions from "../users/store/actions";
import connect from "react-redux/es/connect/connect";

/* Styles for elements */
const styles = theme => ({
    root: {
        background    : "url('/assets/images/backgrounds/jst_bg.png') no-repeat",
        backgroundSize: 'cover'
    },
    card: {
        width   : '100%',
        maxWidth: 384
    }
});

const newState = {
    password: '',
    confirmPassword: '',
};

addValidationRule('meetsPasswordComplexity', (values, value) => {
    let passwordIsLongEnough = value.length >= 10;

    let specialChars = /[`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    let lowercase = /[a-z]/;
    let uppercase = /[A-Z]/;
    let digit = /[0-9]/;

    let hasSpecialCharacter = specialChars.test(value);
    let hasLowerCaseLetter = lowercase.test(value);
    let hasUpperCaseLetter = uppercase.test(value);
    let hasDigit = digit.test(value);

    return passwordIsLongEnough && hasSpecialCharacter && hasLowerCaseLetter && hasUpperCaseLetter && hasDigit;
});

addValidationRule('isEqual', (values, value, secondField) => {
    return value === values[secondField]
});

class ResetPasswordPage extends Component {
    state = {
        ...newState,
        canSubmit: false,
    };

    handleChange = (event) => {
        this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
    };

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onSubmit = (model) => {
        this.props.updatePassword({
            id: this.props.userId,
            password: model.password,
        }).then(() => {
            this.props.history.push('/welcome')
        });
    };

    render()
    {
    	const {classes} = this.props;
    	return (

    		<div className={classNames(classes.root, "flex flex-col flex-auto flex-no-shrink items-center justify-center p-32")}>

                <div className="flex flex-col items-center justify-center w-full">

                	<Grow in={true}>
                        <Card className={classes.card}>

                        	<CardContent className="flex flex-col items-center justify-center p-32">

                        		<img className="w-128 m-32" src="assets/images/logos/jst_logo.png" alt="logo"/>
                        		<Typography variant="title" className="mt-16 mb-32">RESET YOUR PASSWORD</Typography>

                                <Formsy
                                    onValidSubmit={this.onSubmit}
                                    onValid={this.enableButton}
                                    onInvalid={this.disableButton}
                                    ref={(form) => this.form = form}
                                    className="flex flex-col justify-center w-full"
                                >
                                    <TextFieldFormsy
                                        className="mb-16"
                                        required
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        validations="meetsPasswordComplexity"
                                        validationError="Required: 10+ characters with 1+ lowercase, 1+ uppercase, 1+ number, and 1+ special character"
                                        onChange={this.handleChange}
                                    />
                                    <TextFieldFormsy
                                        className="mb-16"
                                        required
                                        fullWidth
                                        label="Confirm Password"
                                        type="password"
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        validations="isEqual:password"
                                        validationError="Passwords must be equal"
                                        onChange={this.handleChange}
                                    />

                                    <Button
                                        type="submit"
                                        variant="raised"
                                        color="primary"
                                        className="w-224 mx-auto mt-16"
                                        aria-label="Reset"
                                    >
                                        RESET MY PASSWORD
                                    </Button>
                                </Formsy>

                                <div className="flex flex-col items-center justify-center pt-32 pb-24">
                                    <Link className="font-medium" to="/pages/auth/login">Go back to login</Link>
                                </div>

                        	</CardContent>

                        </Card>
					</Grow>

				</div>
			</div>
    	);
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        updatePassword: Actions.updatePassword
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        userId: state.auth.user.data.userId,
        newUser: state.userStore.users.entities,
    }
}

export default withStyles(styles, {withTheme: true}) (withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)));