import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {FusePageSimple, FuseMessage} from '@fuse';
import ProductsHeader from './ProductsHeader'
import Product from './Product'
import {showMessage} from '../../../store/actions/fuse/message.actions'
import * as ProductActions from '../subscriptions/store/actions'
import * as CreditActions from '../payment-methods/store/actions/credit-methods.actions'
import * as BankActions from '../payment-methods/store/actions/bank-methods.actions'
import * as AutopayActions from '../autopay/store/actions/index';

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing.unit * 8,
    },
})

class Products extends Component {

    componentDidMount() {
        const {user} = this.props;
        if(user.data.orgId) this.props.fetchProducts(user.data.orgId);
        if(user.data.orgId) this.props.fetchCreditCard(user.data.orgId);
        if(user.data.orgId) this.props.fetchBankAccounts(user.data.orgId)
    }

    setAutoPay = (CreditMethod, id) => {
        return this.props.setAutoPay(CreditMethod, id)
        .then(this.successMessage, this.errorMessage)
    }

    successMessage = () => {

    }

    errorMessage = e => {
        this.props.sendMessage({
            anchorOrigin    : {
                vertical  : 'top',
                horizontal: 'center'
            },
            autoHideDuration: 6000,
            message         : e.message
        })
    }

    render() {
        const {classes, products, cards, accounts} = this.props;
        return (
            <FusePageSimple
                header={
                    <ProductsHeader />
                }
                content={
                    <div className={classes.root}>
                        {Object.keys(products).sort((a,b) => b - a).map(id => (
                            <Product 
                                setAutoPay={this.setAutoPay}
                                key={id} 
                                product={products[id]}
                                cards={cards}
                                accounts={accounts}/>
                        ))}
                    </div>
                }
            />            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendMessage(options) {
            return dispatch(showMessage(options))
        },
        fetchCreditCard(orgId) {
            return dispatch(CreditActions.getCreditMethods(orgId))
        },
        fetchBankAccounts(orgId) {
            return dispatch(BankActions.getBankMethods(orgId))
        },
        fetchProducts(orgId) {
            return dispatch(ProductActions.getSubscriptions(orgId))
        },
		setAutoPay(CreditMethod, id) {
			return dispatch(AutopayActions.setAutopay(CreditMethod, id))
		}        
    }
}


function mapStateToProps(state) {
    return {
        user: state.auth.user,
        products: state.subscriptionsReducer.subscriptions,
        cards: state.bankMethodsReducer.creditMethods.entities,
        accounts: state.bankMethodsReducer.bankMethods.entities,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(Products))
