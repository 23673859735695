import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as userActions from './store/actions';
import {bindActionCreators} from 'redux';
import * as Actions from '../store/actions';
import jwtService from '../jwtService';

class Auth extends Component {

    componentDidMount()
    {
        this.checkIfAuthenticated()
    }

    checkIfAuthenticated() {
        if ( jwtService.isAuthenticated() )
        {
            const tokenData = jwtService.getUserData();
            this.props.setUserDataFromJWT(tokenData);
        }
    }

    render()
    {
        const {children} = this.props;

        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
            setUserDataFromJWT: userActions.setUserDataFromJWT,
            showMessage        : Actions.showMessage,
            hideMessage        : Actions.hideMessage
        },
        dispatch);
}

export default connect(null, mapDispatchToProps)(Auth);
