import axios from 'axios/index';

export const GET_ORGANIZATION_INFO = '[WELCOME STORE] GET ORGANIZATION INFO';

export function getOrganizationInfo(organizationId)
{
    const request = axios.get(`/api/organization/`, {
        organizationId
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type   : GET_ORGANIZATION_INFO,
                payload: response.data,
                organizationId
            })
        );
}