import {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {withRouter} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import * as Actions from '../../../auth/store/actions'
import {bindActionCreators} from "redux";

const styles = theme => ({
    root: {
        background    : "url('/assets/images/backgrounds/jst_bg.png') no-repeat",
        backgroundSize: 'cover'
    },
    card: {
        width   : '100%',
        maxWidth: 384
    }
});

class LogoutPage extends Component {

    componentDidMount()
    {
        this.props.logoutUser();
    }

    render()
    {
        return null;
    }
}
function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        logoutUser: Actions.logoutUser
    }, dispatch);
}

function mapStateToProps({auth})
{
    return {
        user : auth.user
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutPage)));