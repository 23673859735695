import React, { Component } from 'react';
import * as Actions from './store/actions';

import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {FusePageSimple} from '@fuse';
import ReactTable from "react-table";
import {AppBar, Button, Chip, Icon, IconButton, FormControlLabel, Toolbar, Typography, Radio} from '@material-ui/core';
import Formsy from 'formsy-react';
import {RadioGroupFormsy} from '@fuse';
import _ from 'lodash';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import InvoicesHeader from 'main/content/invoices/InvoicesHeader';
import InvoicesAutopayCTA from './InvoicesAutopayCTA';
import NavLink from "react-router-dom/es/NavLink";

const styles = theme => ({
	appBar: {
        backgroundColor: "#187DC3"
    },
    button : {
    	backgroundColor: '#187DC3'
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 500,
        maxWidth: 2000,
    }
});

// const newState = {
// 	invoices: [],
// 	balances: [],
// 	creditMethod: '',
// 	bankMethod: '',
// 	amount: '',
// 	selectedInvoiceIds: [],
// 	selectedInvoice: ''
// };

function Paypal(invoice) {
	if(undefined === invoice.invoice) { return ""; } 
	let formActionUrl = "https://www.sandbox.paypal.com/cgi-bin/webscr"
	let notifyUrl = "https://jst-staging.chargeover.com/r/paypal/ipn"
	let hostedButtonId= "DE5PW2WQ7ZSY2"
	let pixelUrl = "https://www.sandbox.paypal.com/en_US/i/scr/pixel.gif"
	if(process.env.REACT_APP_ENV === "prod") {
		formActionUrl = "https://www.paypal.com/cgi-bin/webscr"
		hostedButtonId = "YYYM9LVLP2358";
		notifyUrl = "https://jst.chargeover.com/r/paypal/ipn"
		pixelUrl = "https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
	}
	return (
	<form action={formActionUrl} method="post" target="_blank"> 
	<input type="hidden" name="cmd" value="_s-xclick" />
	<input type="hidden" name="invoice" value={invoice.invoice.token} />
	<input type="hidden" name="amount" value={invoice.invoice.amount} />
	<input type="hidden" name="hosted_button_id" value={hostedButtonId} />
	<input type="hidden" name="dep-env" value={process.env.REACT_APP_ENV} />
	<input type="hidden" name="notify_url" value={notifyUrl} />
	<input type="image" src="/assets/images/paypal/paypal.png" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
	<img alt="" border="0" src={pixelUrl} width="1" height="1" />
	</form>
	)
}

class InvoicesMain extends Component {

	constructor(props)
	{
		super(props);
		this.state = {
			showList: true, 
			showForm: false, 
			showConf: false, 
			paymentAmount: 0,
			paymentMethodLabel: null,
			isPaying: false,
			creditMethod: null,
			bankMethod: null
		};
	}

	componentDidMount()
    {
        this.props.getInvoices(this.props.orgId);
        this.props.getCreditMethods(this.props.orgId);
        this.props.getBankMethods(this.props.orgId);
    }
    
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
            this.props.getInvoices(this.props.match.params);
            this.props.getCreditMethods(this.props.match.params);
			this.props.getBankMethods(this.props.match.params);
		}
    }

	getFilteredArray = (entities) => {
		return Object.keys(entities).map((id) => entities[id]);
	};


    submitPayment = async function() {
		let payObj = {};
		this.setState({isPaying: true})
		
		let selInvObj = _.find(this.props.invoices, ['id', this.props.selectedInvoice]);
		payObj.amount = selInvObj.balance;
		payObj.appliedTo = [{invoiceId: this.props.selectInvoice}];

		payObj.appliedTo = [{invoiceId: this.props.selectedInvoice, balance: selInvObj.balance}];

		let paymentMethodId = 0;
		payObj.paymentType = "ach";
		if(this.state.creditMethod > 0) {
			payObj.paymentType = "credit"
			payObj.paymentMethodId = this.state.creditMethod;
		} else {
			payObj.paymentMethodId = this.state.bankMethod;
		}

		try {
			let res = await this.props.makePayment(payObj, this.props.orgId);
			this.setState({
				isPaying: false,
				showConf: true
			})
		}
		catch(e) {
			console.error(e)
			this.setState({
				isPaying: false,
			})
		}
	}
	
	formatCurrency = function(amount) {
		let number = parseFloat(amount);
		return "$" + number.toFixed(2);
	};

	render()
	{
		const {classes, invoices, selectInvoice, downloadInvoice, creditMethods, bankMethods } = this.props;
		const {showList, showForm, showConf} = this.state;

		const invoicesData = this.getFilteredArray(invoices);
		const creditMethodsData = this.getFilteredArray(creditMethods);
		const bankMethodsData = this.getFilteredArray(bankMethods);

		return (

			<React.Fragment>
				<FusePageSimple
					header={
						<InvoicesHeader pageLayout={() => this.pageLayout}/>
					}
					content={
						<div>

							<div style={{ display: (showList === true ? 'block' : 'none') }} className="ml-24 mr-24 mt-24 mb-24">
								<AppBar
				                    position="static"
				                    className={classes.appBar}
				                >
				                    <Toolbar>
				                        <Icon className="text-32 mr-12">description</Icon>
				                        <Typography variant="title" color="inherit" className={classes.flex}>
				                            Invoices List
				                        </Typography>
				                    </Toolbar>
				                </AppBar>
								<ReactTable
									className="-striped -highlight"
									getTrProps={(state, rowInfo, column) => {
					                    return {
					                        className: "cursor-pointer",
					                    }
					                }}
									data={invoicesData}
									columns={[
										
										{
											columns: [										
												{
													Header   : "Invoice Number",
													accessor : "id",
                                                    className: "justify-center",
													headerStyle: {
														fontWeight: "bold"
													},
													width: 200,
													minWidth: 200
												},
												{
													Header   : "Subscription Number",
													accessor : "subscriptionId",
                                                    className: "justify-center",
													headerStyle: {
														fontWeight: "bold"
													},
													width: 200,
													minWidth: 200
												},	
							                    {
							                        Header  : "Invoice Date",
							                        accessor: "invoiceDate",
                                                    className: "justify-center",
							                        headerStyle: {
														fontWeight: "bold"
													},
													minWidth: 75
							                    },
							                    {
							                        Header  : "Due Date",
							                        accessor: "dueDate",
                                                    className: "justify-center",
							                        headerStyle: {
														fontWeight: "bold"
													},
													minWidth: 75
							                    },
							                    {
							                        Header  : "Paid Date",
							                        accessor: "paidDate",
                                                    className: "justify-center",
							                        headerStyle: {
														fontWeight: "bold"
													},
													minWidth: 75
							                    },
							                    {
							                        Header  : "Amount",
							                        accessor: "amount",
                                                    className: "justify-center",
							                        headerStyle: {
														fontWeight: "bold"
													},
													minWidth: 50,
													Cell: props => this.formatCurrency(props.value)
							                    },
							                    {
							                        Header  : "Balance",
							                        accessor: "balance",
                                                    className: "justify-center",
							                        headerStyle: {
														fontWeight: "bold"
													},
													minWidth: 50,
													Cell: props => this.formatCurrency(props.value)
							                    },
							                    {
							                        Header  : "Status",
							                        accessor: "status",
                                                    className: "justify-center",
							                        headerStyle: {
														fontWeight: "bold"
													},
													minWidth: 50
							                    },
							                    {
					                            	Header: "Actions",
					                            	headerStyle: {
														fontWeight: "bold"
													},
													minWidth: 50,
					                            	Cell  : row => (
							                            <div className="flex items-center">
							                                <IconButton
							                                	onClick={() => {
																	downloadInvoice(row.original.id)
							                                	}}
							                                >
							                                    <Icon>cloud_download</Icon>
							                                </IconButton>
															{row.original.state !== 'c' ? 
															<IconButton onClick={() => {
																let invoice = _.find(this.props.invoices,['id',row.original.id])
																this.setState({
																	showList: false,
																	showForm: true,
																	paymentAmount: invoice.balance.toFixed(2)
																})
																selectInvoice(row.original.id)
															}}>
															<Icon>payment</Icon>
															</IconButton>
															:''}
															
							                            </div>
							                        ),
							                        sortable : false,
							                        className: "justify-center"
					                            }
							                ]
							            }
							        ]}
							       	defaultPageSize={10}
							        noDataText="No invoices found"
                                    showPagination={invoicesData.length > 10}
                                />
								
							</div>

							{/* PAYMENT FORM */}
							<div style={{ display: (showForm === true ? 'block' : 'none') }} className={classNames(classes.root, "max-w-sm ml-24 mr-24 mt-24 mb-24")}>
							<Typography className="h2">Amount to be Paid: <b>${this.state.paymentAmount}</b></Typography>
								<Typography className="h2">Select Payment Method</Typography>
								<Formsy
									onValidSubmit={this.onSubmit}
									className="flex flex-col justify-center"
									ref={(form) => this.form = form}
									onChange = {(values) => { 
										let bankMethod = 0;
										let creditMethod = 0;
										let paymentMethodLabel = "";
										if(values.selectPaymentMethod.substr(0,3) == "cre") {
											creditMethod = values.selectPaymentMethod.substr(4);
											paymentMethodLabel = "Credit card ending in " + this.props.creditMethods[creditMethod].maskedNumber;

										} else {
											bankMethod = values.selectPaymentMethod.substr(4);
											paymentMethodLabel = "Bank account ending in " + this.props.bankMethods[bankMethod].maskedAccount;
										}
										this.setState({
											paymentMethodId: values.selectPaymentMethod,
											bankMethod: bankMethod,
											creditMethod: creditMethod,
											paymentMethodLabel: paymentMethodLabel
										});
									}}
								>
										<RadioGroupFormsy 
											label="Pay with saved payment method" 
											name="selectPaymentMethod"
											className="my-24">
											{bankMethodsData.map(bankMethod => (
											<FormControlLabel
												value={"ach-"+bankMethod.id}
												label={"Bank account ending in " + bankMethod.maskedAccount}
												control={<Radio/>}
			                                />
											))}
											
											{creditMethodsData.map(creditMethod => (
											<FormControlLabel
												value={"cre-"+creditMethod.id}
												label={"Credit card ending in " + creditMethod.maskedNumber}
												control={<Radio/>}
			                                />
											))}
										</RadioGroupFormsy>
									</Formsy>
									
									
				                    <div>
					                    <Button
											type="submit"
											onClick={() =>
												this.setState({
													showForm: !showForm,
													showConf: !showConf,
												}, function() {
														//console.log('Saved invoices to state!')
												})
											}
					                        variant="raised"
					                        color="secondary"
					                        className={classNames(classes.button, "normal-case mt-24 mb-24 max-w-160")}
					                        aria-label="CONTINUE AGAIN"
					                    >
					                        CONTINUE
					                    </Button>
					                    <Button
					                    	component={NavLink}
											to="/payment-methods"
					                    	target="_blank"
					                        variant="raised"
					                        color="secondary"
					                        className={classNames(classes.button, "normal-case mt-24 mb-24 ml-24 max-w-192")}
					                        aria-label="ADD PAYMENT METHOD"
					                    >
					                        ADD PAYMENT METHOD
					                    </Button>
										<Paypal invoice={_.find(this.props.invoices, ['id', this.props.selectedInvoice])} />

					                </div>
								
								

							</div>

							{/* CONFIRMATION VIEW */}
			                <div style={{ display: (showConf === true ? 'flex' : 'none') }} className="confirmation ml-24 mr-24 mt-24 mb-24">
			                	<div>

				                	{/* Title */}
					                <Typography variant="title">{this.props.showThanks !== true ? 'Confirm Payment' : 'Thank You!'}</Typography>
					                <Typography style={{ display: (this.props.showThanks === true ? 'block' : 'none') }} className="h3 mb-12">Your payment is being processed.</Typography>
									
									{/* Error message if payment does not go through */}
									{this.props.paymentError === true && 
										<div>
											<Typography className="h2 text-red">There was an error processing your payment. Please check your credit card details.</Typography>
											<Typography className="text-red">{this.props.paymentErrorMsg}</Typography>
										</div>
									}
									
									<Typography className="h2 mb-24" style={{ display: (this.props.showThanks === true ? 'block' : 'none') }}><p>Monthly Subscription/Rental Customers, go to <a href="/activate/">Activation</a> to Activate your Collectmax System</p></Typography>

					            	{/* Details */}
					                <Typography className="h2">Invoices:</Typography>
									
					                <div className={classes.chips}>
										<Chip key={this.props.selectedInvoice} label={this.props.selectedInvoice} className={classes.chip} />
		                            </div>
		                            <Typography className="h2">{this.props.showThanks !== true ? 'Amount To Pay: $' : 'Amount Paid: $'}<b>{this.state.paymentAmount}</b></Typography>
		                            <Typography className="h2">Payment Method : <b>{this.state.paymentMethodLabel}</b>
					                </Typography>
					                <Typography style={{ display: (this.props.showThanks === true ? 'block' : 'none') }} className="h2">Confirmation ID: <b>{this.props.confirmationId}</b></Typography>

					                <Button
										style={{ visibility: (this.props.showThanks  === true ? 'hidden' : 'visible') }}
										disabled={this.state.isPaying}
										onClick={() => {
											this.submitPayment()
										}}
										onDoubleClick={() => {
											alert("Double click not allowed!")
										}}
										type="submit"
										id="btn"
				                        variant="raised"
				                        color="secondary"
				                        className={classNames(classes.button, "normal-case mt-24 mb-24 max-w-160")}
				                        aria-label="SUBMIT PAYMENT"
				                    >
				                    	MAKE PAYMENT
				                    </Button>
				                    <Button
				                    	style={{ visibility: (this.props.showThanks  === true ? 'hidden' : 'visible') }}
				                    	onClick={() =>
				                    		this.setState({
				                    			showList: false,
				                    			showForm: true,
				                    			showConf: false,
				                    		})
				                    	}
				                        type="submit"
				                        variant="raised"
				                        color="secondary"
				                        className={classNames(classes.button, "normal-case mt-24 mb-24 ml-24 max-w-160")}
				                        aria-label="MAKE CHANGES"
				                    >
				                    	UPDATE PAYMENT
				                    </Button>
					                <Button
										style={{ visibility: (this.props.showThanks  !== true ? 'hidden' : 'visible') }}
										onClick={() => {
											this.props.getInvoices(this.props.orgId)
											.then(() => {
												this.setState({
													showList: true,
													showForm: false,
													showConf: false,
												})
											})
										}}
										onDoubleClick={() => {
											alert("Double click not allowed!")
										}}
										type="submit"
										id="btn"
				                        variant="raised"
				                        color="secondary"
				                        className={classNames(classes.button, "normal-case mt-24 mb-24 max-w-160")}
				                        aria-label="Return to invoices"
				                    >
				                    	RETURN TO INVOICES
				                    </Button>									
				                   </div>
			                    {this.props.showThanks && (<InvoicesAutopayCTA/>)}
			                </div>
						</div>
					}
				/>
			</React.Fragment>
		)
	}

}

function mapDispatchToProps(dispatch)
{
	return bindActionCreators({
		getInvoices: Actions.getInvoices,
		selectAllInvoices: Actions.selectAllInvoices,
		selectInvoice: Actions.selectInvoice,
		deselectAllInvoices: Actions.deselectAllInvoices,
		downloadInvoice: Actions.getInvoiceDownload,
        toggleInSelectedInvoices: Actions.toggleInSelectedInvoices,
        getCreditMethods: Actions.getCreditMethods,
        getBankMethods: Actions.getBankMethods,
        toggleInSelectedCreditMethod: Actions.toggleInSelectedCreditMethod,
		makePayment: Actions.makePayment,
	}, dispatch);
}

function mapStateToProps(state)
{
	return {
		invoices: state.invoices.invoices.invoiceEntities,
		selectedInvoiceIds: state.invoices.invoices.selectedInvoiceIds,
		selectedInvoice: state.invoices.invoices.selectedInvoice,
		selectedInvoiceBalances: state.invoices.invoices.selectedInvoiceBalances,
		creditMethods: state.invoices.invoices.creditMethodEntities,
		bankMethods: state.invoices.invoices.bankMethodEntities,
		selectedCreditMethod: state.invoices.invoices.selectedCreditMethod,
		paymentAmount: state.invoices.invoices.paymentAmount,
		confirmationId: state.invoices.invoices.confirmationId,
		showThanks: state.invoices.invoices.showThanks,
		paymentError: state.invoices.invoices.paymentError,
		paymentErrorMsg: state.invoices.invoices.paymentErrorMsg,
		orgId: state.auth.user.data.orgId
	}
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesMain)));