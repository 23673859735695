import ErrorPage from './ErrorPage';

export const ErrorConfig = {
    routes  : [
        {
            path: '/error',
            component: ErrorPage,
            settings: {
                layout: {
                    navbar: 'none',
                    toolbar   : 'none',
                    footer    : 'none'
                }
            }
        }
    ]
};
