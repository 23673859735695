import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {Hidden, Icon, IconButton, Typography} from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    root: {}
});

class ActivateHeader extends Component {
	render()
    {
        const {classes, pageLayout} = this.props;
        return (
            <div className={classNames(classes.root, "flex flex-1 flex-col sm:flex-row items-center justify-between p-24")}>

                <div className="flex flex-1 items-center">
                    {/* <Hidden lgUp>
                        <IconButton
                            onClick={(ev) => pageLayout().toggleLeftSidebar()}
                            aria-label="open left sidebar"
                        >
                            <Icon>menu</Icon>
                        </IconButton>
                    </Hidden> */}

                    <div className="flex items-center">
                        <Icon className="text-32 mr-12">power_settings_new</Icon>
                        <Typography variant="headline">Activate</Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(ActivateHeader);