import React from 'react'

export default function svgDownload({className = 'svg-icon', fill = '#000'}) {
    return (
		<svg className={className} fill={fill} height='100px' width='100px' x="0px" y="0px" viewBox="0 0 100 100">
			<g>
				<path d="M50,5C25.1,5,5,25.1,5,50c0,24.9,20.1,45,45,45s45-20.1,45-45C95,25.1,74.9,5,50,5z M42.5,49.3v-20h15v20h10L50,64.3   l-17.5-15H42.5z M72.5,74.3h-45v-5h45V74.3z"></path>
			</g>
		</svg>	
	)
}
