import React, {Component} from 'react';
import {Button, Dialog, DialogActions, Icon, Typography, Toolbar, AppBar} from '@material-ui/core';
import {withStyles} from '@material-ui/core/index';
import {bindActionCreators} from 'redux';
import * as Actions from './store/actions';
import {connect} from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import Formsy, {addValidationRule} from 'formsy-react';
import {TextFieldFormsy} from '@fuse';

const styles = theme => ({
	root: {},
	formControl: {
		marginBottom: 24
	},
    formFields: {
        width: '100%'
    },
    formIcons: {
        color: '#187DC3'
    },
    formItems: {
        display: 'flex',
        alignItems: 'center'
    },
    blueBackground: {
        backgroundColor: "#187DC3",
    }
});
/*
axios.interceptors.request.use(request => {
    console.log('Starting Request', request);
    return request;
});

axios.interceptors.response.use(response => {
    console.log('Response:', response);
    return response;
});
*/
const newCreditMethodState = {
	id: '',
	nameOnCard: '',
	maskedNumber: '',
	expDate: '',
	token: ''
}

// ensure month/year combo is >= to the current month
addValidationRule('monthDateNotPast', (values, value, month) => {
    console.log("Pre month: " + values[month])
    month = Number(values[month])
    console.log("Month = " + month)
    var today = new Date();
    var curMonth = today.getMonth() + 1;
    var curYear = today.getFullYear();
    // If year is in the future always return true
    if(value > curYear) {
        return true;
    // If year is equal to the current year, and the month is this month or in the future return true
    } else if (value == curYear && month >= curMonth) {
        return true;
    // All other cases are invalid
    } else {
        return false;
    }
});

addValidationRule('validCCNum', (values, value) => {
    
// accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0, nDigit = 0, bEven = false;
    value = value.replace(/\D/g, "");

    for (var n = value.length - 1; n >= 0; n--) {
        var cDigit = value.charAt(n)
        nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    return (nCheck % 10) === 0;
});

class CreditMethodsDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...newCreditMethodState,
            canSubmit: false,
            enableValidations: false,
            hasError: null
        };
    }

    handleError() {
        this.setState({ hasError: true });
    }

	componentDidUpdate(prevProps, prevState, snapshot)
	{
		/**
		After Dialog Open
		*/
		if ( !prevProps.creditMethodDialog.props.open && this.props.creditMethodDialog.props.open )
        {
            
            /**
             * Dialog type: 'remove'
             * Update State
             */
            if ( this.props.creditMethodDialog.type === 'remove' &&
                this.props.creditMethodDialog.data &&
                !_.isEqual(this.props.creditMethodDialog.data, prevState) )
            {
                this.setState({...this.props.creditMethodDialog.data});
            }

			/**
			Dialog type: 'new'
			Update State
			*/
			if ( this.props.creditMethodDialog.type === 'new' &&
                !_.isEqual(newCreditMethodState, prevState) )
            {
                this.setState({...newCreditMethodState});
            }
		}
		return null;
	}

	/*handleChange = (event) => {
		this.setState(_.set({...this.state}, event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value));
	};*/

	closeComposeDialog = () => {
		this.props.creditMethodDialog.type === 'edit' ? this.props.closeEditCreditMethodDialog() : this.props.closeNewCreditMethodDialog();
	};

    disableButton = () => {
        console.info("Disable button called");
        this.form.enableValidations = true;
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        console.info("Enable button called.");
        if(this.form != null) {
        
            if(this.form.getModel().nameOnCard === undefined) {
                this.setState({canSubmit: false});
            } else {
                this.setState({canSubmit: true});
            }
        }
    };

    


    onSubmit = (model) => {
        const {addCreditMethod} = this.props;
        addCreditMethod(model, this.props.orgId);
/*        this.setState({
            nameOnCard: model.nameOnCard,
            cardType: model.cardType,
            cardNumber: model.cardNumber,
            expDate: model.expYear + '-' + model.expMonth,
        }, function() {
            addCreditMethod(this.state)
            .catch((e) => this.handleError())
        })*/
        this.closeComposeDialog()
    };

	render()
	{
		const {classes, creditMethodDialog, removeCreditMethod} = this.props;
        const {canSubmit} = this.state;

        //TODO: Check this
        /*if (this.state.hasError === true) {
            throw new Error('I crashed!');
        }*/

		return (
			<Dialog className={classes.root} {...creditMethodDialog.props} onClose={this.closeComposeDialog} fullWidth maxWidth="xs">

				<AppBar position="static">
					<Toolbar className={classNames(classes.blueBackground, "flex w-full")}>
						<Typography variant="subheading" color="inherit">
							{creditMethodDialog.type === 'new' ? 'Add New Credit Card' : 'Remove Credit Card'}
						</Typography>
					</Toolbar>
				</AppBar>

                {creditMethodDialog.type === 'new' ? (

                    <Formsy
                        onValidSubmit={this.onSubmit}
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        ref={(form) => this.form = form}
                        className="mx-24 my-24"
                    >
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>person</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="nameOnCard"
                                label="Name On Card"
                                validations={{
                                    minLength: 2
                                }}
                                validationErrors={{
                                    minLength: "Must be more than 2 characters"
                                }}
                                required
                            />
                        </div>
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="cardNumber"
                                label="Card Number"
                                validations="isNumeric,minLength:15,validCCNum"
                                validationErrors={{
                                    isNumeric: "Please enter only digits for your credit card number",
                                    minLength: "Please enter in a valid credit card number",
                                    validCCNum: "Please enter in a valid credit card number",
                                }}
                                required
                            />
                        </div>
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="ccv"
                                label="CCV"
                                validations="isNumeric,minLength:3"
                                validationErrors={{
                                    isNumeric: "Please enter a numeric CVV that is at least 3-digits long",
                                    minLength: "Please enter a numeric CVV that is at least 3-digits long"
                                }}
                                required
                            />
                        </div>
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>calendar_today</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text" 
                                name="expMonth"
                                label="Expiration Month"
                                placeholder="2 Digit Month"
                                validations="isNumeric,isLength:2"
                                validationErrors={{
                                    isNumeric: "Please enter the Expiration Month in 2-digit format",
                                    isLength: "Please enter the Expiration Month in 2-digit format"
                                }}
                                required
                            />
                        </div>
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>calendar_today</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="expYear"
                                label="Expiration Year"
                                placeholder="4 Digit Year"
                                validations="isNumeric,isLength:4,monthDateNotPast:expMonth"
                                validationErrors={{
                                    isNumeric: "Please enter the Expiration Year in 4-digit format",
                                    isLength: "Please enter the Expiration Year in 4-digit format",
                                    monthDateNotPast: "Please enter an expiration date that is not in the past"
                                }}
                                required
                            />
                        </div>
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="address"
                                label="Address"
                                required
                            />
                        </div>
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="city"
                                label="City"
                                required
                            />
                        </div>
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="state"
                                label="State"
                                required
                            />
                        </div>
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="postalCode"
                                label="Postal Code"
                                required
                            />
                        </div>
                        <div className={classes.formItems}>
                            <Icon className={classes.formIcons}>credit_card</Icon>
                            <TextFieldFormsy
                                className={classNames(classes.formFields, "ml-24 mb-24")}
                                type="text"
                                name="country"
                                label="Country"
                                required
                            />
                        </div>
                        <Button
                            type="submit"
                            variant="raised"
                            color="secondary"
                            className={classNames(classes.button, "normal-case mt-24 mb-24 max-w-192")}
                            aria-label="ADD CREDIT CARD"
                            disabled={!canSubmit}
                        >
                            ADD CREDIT CARD
                        </Button>

                    </Formsy>
                ) : (
                    <div>
                        <Typography className="h2 p-24">Are you sure you want to delete payment method ending in {this.state.maskedNumber}?</Typography>
                        <DialogActions className="justify-between pl-16">
                            <div className="mb-24">
                                <Button
                                    className={classes.blueBackground}
                                    variant="raised"
                                    color="primary"
                                    onClick={() => {
                                        removeCreditMethod(this.state.id, this.props.orgId)
                                        .catch((e) => this.handleError());
                                        this.closeComposeDialog();
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    className={classNames(classes.blueBackground, "ml-24")}
                                    variant="raised"
                                    color="primary"
                                    onClick={() => {
                                        this.closeComposeDialog();
                                    }}
                                >
                                    No
                                </Button>
                            </div>
                        </DialogActions>
                    </div>

                )}
                
			</Dialog>
		);
	}
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        closeNewCreditMethodDialog : Actions.closeNewCreditMethodDialog,
        addCreditMethod            : Actions.addCreditMethod,
        removeCreditMethod         : Actions.removeCreditMethod
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        creditMethodDialog: state.creditMethodsReducer.creditMethods.creditMethodDialog,
        orgId: state.auth.user.data.orgId
    }
}


export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(CreditMethodsDialog));
