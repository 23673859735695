import Autopay from 'main/content/autopay/Autopay';
import AutopayRedirect from 'main/content/autopay/AutopayRedirect';
import authRoles from 'auth/authRoles';

export const AutopayConfig = {
	auth: authRoles.billing,
	routes: [
		{
			path: '/autopay',
			component: AutopayRedirect
		},		
		{
			path: '/subscription/:organizationId/:subscriptionId/autopay',
			component: Autopay
		},
	]
};