import Activate from 'main/content/activate/Activate';
import authRoles from "../../../auth/authRoles";


export const ActivateConfig = {
    auth: authRoles.support,
	routes: [
		{
			path: '/activate',
			component: Activate
		},
	]
};