import React, {Component} from 'react';
import {Icon, Typography} from '@material-ui/core';

class PaymentMethodsHeader extends Component {
	render()
	{
		return (
			<div className ="flex flex-1 flex-col sm:flex-row items-center justify-between p-24">
				<div className="flex flex-1 items-center">
                    <div className="flex items-center">
	                    <Icon className="text-32 mr-12">attach_money</Icon>
	                    <Typography variant="headline">Billing</Typography>
                    </div>
                </div>
            </div>
		);
	}
}

export default PaymentMethodsHeader;