import * as Actions from '../actions';
import _ from 'lodash';

const initialState = {
	entities: {},
	routeParams: {},
	creditMethodDialog: {
		type: 'new',
		props: {
			open: false
		},
		data: null
	}
};

const creditMethodsReducer = function(state = initialState, action) {

	switch ( action.type ) {
        case Actions.GET_CREDIT_METHODS: {
            return {
                ...state,
                entities: _.keyBy(action.payload, 'id'),
                organizationId: action.organizationId
            };
        }
        case Actions.OPEN_NEW_CREDIT_METHOD_DIALOG: {
            return {
                ...state,
                creditMethodDialog: {
                    type: 'new',
                    props: {
                        open: true
                    },
                    data: null
                }
            };
        }
        case Actions.CLOSE_NEW_CREDIT_METHOD_DIALOG: {
            return {
                ...state,
                creditMethodDialog: {
                    type: 'new',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case Actions.OPEN_REMOVE_CREDIT_METHOD_DIALOG: {
            return {
                ...state,
                creditMethodDialog: {
                    type: 'remove',
                    props: {
                        open: true
                    },
                    data: action.data
                }
            };
        }
        case Actions.CLOSE_REMOVE_CREDIT_METHOD_DIALOG: {
            return {
                creditMethodDialog: {
                    type: 'remove',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case Actions.ADD_CREDIT_METHOD: {
            console.log("Added credit method");
            return {
                ...state
            }
        }
        case Actions.REMOVE_CREDIT_METHOD: {
            console.log("Remove credit method reducer called");
            return {
                ...state
            }
        }
		default: {
        	return state;
		}
	}
};

export default creditMethodsReducer;