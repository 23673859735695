import MyUser from './MyUser';
import authRoles from "../../../auth/authRoles";

export const MyUserConfig = {
    routes  : [
        {
            path     : '/myprofile',
            component: MyUser,
            auth: authRoles.allUsers
        }
    ]
};
