import * as Actions from '../actions';

/*
Initialize the state to start as blank.
*/
const initialState = {
	creditMethodEntities: [],
    bankMethodEntities: [],
	selectedCreditMethod: '',
	routeParams: {},
}


const autopay = function (state = initialState, action)
{
	switch (action.type)
	{
        
		case Actions.TOGGLE_IN_SELECTED_CREDIT_METHOD:
        {
        	const creditMethodId = action.creditMethodId;
            let selectedCreditMethod = [...state.selectedCreditMethod];

            /* I'll have to look through this logic */
            if ( selectedCreditMethod.find(id => id === creditMethodId) !== undefined )
            {
                selectedCreditMethod = selectedCreditMethod.filter(id => id !== creditMethodId);
            }
            else
            {
                selectedCreditMethod = [...selectedCreditMethod, creditMethodId];
            }

            return {
                ...state,
                selectedCreditMethod: selectedCreditMethod
            };
        }
        case Actions.SET_AUTOPAY:
        {
            let enabled = true;
            let achId = 0;
            let creditCardId = 0;
            if(action.payload.paymentType === "inv") {
                console.log("Disabling autopay in reducer");
                enabled = false;
            } else if (action.payload.paymentType === "creditcard") {
                creditCardId = action.payload.paymentMethodId;
            } else {
                achId = action.payload.paymentMethodId;
            }
            return {
                ...state,
                achId: achId,
                creditCardId: creditCardId,
                enabled: enabled
            };
        }
		default:
		{
			return state;
		}
	}
};

export default autopay;