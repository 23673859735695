import React, {Component} from 'react';

import {Card, CardContent, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles/index';

import classNames from 'classnames';

const styles = theme => ({
	bodyMessage: {
		fontSize: 25
	},
	card: {
		maxWidth: 600,
		textAlign: 'center',
		paddingBottom: 50
	},
	root: {
		background    : "url('/assets/images/backgrounds/jst_bg.png') no-repeat",
        backgroundSize: 'cover'
	},
	titleMessage: {
		fontSize: 40
	},
});

class ErrorPage extends Component {

	render()
	{

		const {classes} = this.props;

		return (

			<div className={classNames(classes.root, "flex flex-col flex-auto flex-no-shrink items-center justify-center p-32")}>

                <div className="flex flex-col items-center justify-center w-full">
                    <Card className={classes.card}>

                        <CardContent className="flex flex-col items-center justify-center p-32">

                            <img className="w-128 m-32" src="assets/images/logos/jst_logo.png" alt="logo"/>

                            <Typography variant="headline" className={classNames(classes.titleMessage, "mt-16")}>Error</Typography>
                            <Typography variant="headline" className={classNames(classes.bodyMessage, "mt-16")}>We're sorry. There is an error in this request.</Typography>

                        </CardContent>
                    </Card>
                </div>
            </div>
		)
	}
}

export default withStyles(styles, {withTheme: true}) (ErrorPage);