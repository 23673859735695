import React, { Component } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, Card, CardContent, Icon, Toolbar, Typography} from '@material-ui/core';

import _ from 'lodash';

import * as OrganizationActions from '../organization/store/actions';

const styles = theme => ({
	appBar: {
		backgroundColor: "#187DC3"
	},
	balanceDue: {
		fontSize: 30
	},
    button: {
    	backgroundColor: '#187DC3'
    },
    card: {
    	flexBasis: "40%",
    	margin: 20
    },
    cardContent: {
    	paddingLeft: 70
    }
});

const ROLE = 'ROLE_ADMIN';

const newState = {
	role: ROLE,
	orgId: 0
};

class WelcomeCustomerInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...newState,
			hasError: null
		};
	}

	handleError() {
		this.setState({ hasError: true });
	}

	componentDidMount()
    {
    	/*
    	In this line we're mounting the function that gets a single organization with
    	the parameter of the organization ID. This organization ID will actually be
    	acquired by checking the user logged in and what organization they belong to.
    	We still need login working though before that can happen so for now it's hard
    	coded above. Anyway, this line allows us to set the variable `orgInfo` that's
    	being pulled in from the reducers and set below in the mapStateToProps function.
		*/
		if(this.props.orgId !== undefined && this.props.orgId >0) {
			this.props.getOrganizationInfo(this.props.orgId)
		}
        
        //.catch((e) => this.handleError());
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
			if(this.props.organizationId > 0) {
				this.props.getOrganizationInfo(this.props.orgId);
			}
			
        }
    }

	render()
	{
		const {classes, orgInfo} = this.props;

        return (
			<Card className={classNames(classes.card, "")}>

				<AppBar
                    position="static"
                    className={classes.appBar}
                >
                    <Toolbar>
                        <Icon className="text-32 mr-12">person</Icon>
                        <Typography variant="headline" color="inherit">
                            Customer Info
                        </Typography>
                    </Toolbar>
                </AppBar>

				<CardContent className={classes.cardContent}>

					<Typography variant="subheading" color="inherit">
                        <b>Firm Name</b>
                    </Typography>
                    <Typography variant="subheading" color="inherit">
                    	{orgInfo ? orgInfo.name : ""}
                    </Typography>

                    <Typography variant="subheading" color="inherit">
                        <b>Address</b>
                    </Typography>
                    <Typography variant="subheading" color="inherit">
                    	{orgInfo ? orgInfo.address : ""}
                    </Typography>

                    <Typography variant="subheading" color="inherit">
                        <b>Phone</b>
                    </Typography>
                    <Typography variant="subheading" color="inherit">
                    	{orgInfo ? orgInfo.phone : ""}
                    </Typography>

				</CardContent>

			</Card>
		)
	}
}

function mapDispatchToProps(dispatch)
{
	return bindActionCreators({
		getOrganizationInfo: OrganizationActions.getOrganizationInfo,
	}, dispatch);
}

function mapStateToProps(state)
{
	return {
		orgInfo: state.organizationsStore.organization.organizationInfo,
		orgId: state.auth.user.data.orgId
	}
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(WelcomeCustomerInfo)));