import Documents from './Documents';
import authRoles from "../../../auth/authRoles";

export const DocumentsConfig = {
    auth: authRoles.allUsers,
	routes: [
		{
			path: '/documents',
			component: Documents,
		}
	]
};
