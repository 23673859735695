import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {FuseUtils} from '@fuse';
import {Typography} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import _ from 'lodash';

const styles = theme => ({
});

class InvitationList extends Component {

    constructor(props) {
        super(props);
        this.state = {hasError: null};
    }

    componentDidMount() {
        this.props.getInvites(this.props.orgId)
            .catch((e) => this.handleError());
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
            console.log("reload triggered")
            this.props.getInvites(this.props.orgId)
                .catch((e) => this.handleError());

		}
    }

    handleError() {
        this.setState({ hasError: true });
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if ( searchText.length === 0 )
        {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    render() {
        const {invites, classes} = this.props;
        const data = this.getFilteredArray(invites, "");

        if ( !data || data.length === 0 )
        {
            return (
                <div className="flex items-center justify-center h-full">
                    <Typography color="textSecondary" variant="headline">
                        There are no pending invites!
                    </Typography>
                </div>
            );
        }

        return (
            <div>
                <ReactTable 
                    className={classNames(classes.root, "-striped -highlight")}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            className   : "cursor-pointer",
                        }
                    }}
                    columns={[
                        {
                            columns: [
                                {
                                    Header      : "First Name",
                                    accessor    : "firstName",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 150
                                },
                                {
                                    Header      : "Last Name",
                                    accessor    : "lastName",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 175
                                },
                                {
                                    Header      : "Email",
                                    accessor    : "emailAddress",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 300
                                },
                                {
                                    Header      : "Role",
                                    accessor    : "role",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 150
                                },
                                {
                                    Header      : "Status",
                                    accessor    : "isExpired",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 100,
                                    //Cell: ({value}) => (value === true ? "PENDING" : "")
                                    Cell: ({value}) => (value === true ? "EXPIRED" : "PENDING")
                                }
                            ]
                        }
                    ]}                    data = {data}
                    defaultPageSize={5}
                    noDataText="No invites found"
                    showPagination={data.length > 5}
                    style={{
                        height: 350
                    }}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getInvites          : Actions.getInvites,
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        invites            : state.userStore.users.invites,
        orgId: state.auth.user.data.orgId
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitationList)));