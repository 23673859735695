import LoginPage from './Login';
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import authRoles from "../../../auth/authRoles";

export const LoginConfig = {
    settings: {
        layout: {
            navbar: 'none',
            toolbar   : 'none',
            footer    : 'none'
        }
    },
    routes  : [
        {
            path     : '/login',
            component: LoginPage
        },
        {
            path     :   '/forgot-password',
            component: ForgotPasswordPage
        },
        {
        	path     : '/password-reset',
        	component: ResetPasswordPage,
            auth: authRoles.allUsers
        },
    ]
};
