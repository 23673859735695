import axios from 'axios/index';

export const GET_USER = '[MYUSER STORE] GET USER';
export const UPDATE_USER = '[MYUSER STORE] UPDATE USER';


/* GET all users */
export function getUser(userId)
{
	const request = axios.get('/api/user/' + userId);

	return (dispatch) =>
		request.then((response) =>
			dispatch({
				type: GET_USER,
				payload: response.data,
			})
		);
}


/* POST new user */
export function updateUser(myuser)
{
	return (dispatch, getState) => {
		const request = axios.post('/api/user/' + myuser.userId, {
			myuser
		});

		return request.then((response) => 
				dispatch({
					type: UPDATE_USER
				})
		);
	};
}