import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import {AppBar, Button, Card, CardActions, CardContent, Toolbar, Typography} from '@material-ui/core';

import _ from 'lodash';
import classNames from 'classnames';

import * as Actions from './store/actions';

const styles = theme => ({
    appBar: {
        backgroundColor: "#187DC3"
    },
    layoutRoot: {},
    card: {},
    changeSubscription: {
        backgroundColor: "#187DC3",
        width: "18rem"
    },
    actions: {
        marginBottom: 16
    },
});

const newSubscriptionState = {
    "status": "",
    "organizationId": "",
    "subscriptionId": "",
    "paymentMethod": "",
    "achId":"",
    "creditCardId": "",
    "nextBillDate": "",
    "lineItems": [
        {
            "subscriptionId": "",
            "itemId": "",
            "name": "",
            "quantity": "",
            "type": "",
            "units": ""
        }
    ],
    "id": ""
}

class SubscriptionSummary extends Component {

    state = {...newSubscriptionState}

    componentDidMount()
    {
         this.props.getSubscriptions(this.props.match.params);
    }

    componentDidUpdate(prevProps, prevState)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
             this.props.getSubscriptions(this.props.match.params);
        }
    }

    getFilteredArray = (entities) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        return arr;
    };

    render()
    {
        const {classes, subscriptionItems} = this.props;

        /* Take all the subscription items and group them by id to make objects */
        const data = this.getFilteredArray(subscriptionItems);

        return (
            <div className="max-w-xl mt-24 mb-24 ml-24">
                <AppBar
                    position="static"
                    className={classNames(classes.appBar, "mt-40")}
                >
                    <Toolbar>
                        {/*<Icon className="text-32 mr-12">account_balance</Icon>*/}
                        <Typography variant="title" color="inherit" className={classes.flex}>
                            Manage CollectMax
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Card className={classes.card}>
                    <CardContent>

                        {/* Take all the objects, provide an ID, and list them */}
                        {data.map((object, i) => (
                            <div className={"row"} key={i}> 
                                <Typography className="mb-16 mt-16" variant="subheading"><b>CollectMax:</b>
                                {object.lineItems.map((lineitem, j) => (
                                    <div className={"row"} key={j}>
                                         {lineitem.type === 'CollectMax Enterprise' ? lineitem.type : console.log(lineitem)}
                                    </div>
                                ))}
                                </Typography>

                                <Typography className="mb-16 mt-16" variant="subheading"><b>Seats:</b>
                                {object.lineItems.map((lineitem, j) => (
                                    <div className={"row"} key={j}>
                                         {lineitem.type === 'CollectMax Enterprise' ? lineitem.quantity : console.log('Not what I want')}
                                    </div>
                                ))}
                                </Typography>

                                {/* Because there's a nested key, value piece we must iterate here as well */}
                                <Typography className="mt-16" variant="subheading"><b>Module:</b>
                                {object.lineItems.map((lineitem, j) => (
                                    <div className={"row"} key={j}>
                                         {lineitem.type !== 'CollectMax Enterprise' ? lineitem.name.substring(lineitem.name.indexOf(":") + 1) : console.log('Not what I want')}
                                    </div>
                                ))}
                                </Typography>
                            </div>
                        ))}
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button
                            type="submit"
                            variant="raised"
                            color="primary"
                            className={classes.changeSubscription}
                            aria-label="UPDATE CollectMax"
                            component={NavLink}
                            to={'/subscription'}
                        >
                            UPDATE CollectMax
                        </Button>
                        <Button
                            type="submit"
                            variant="raised"
                            color="primary"
                            className={classNames(classes.changeSubscription, "ml-24")}
                            aria-label="ADD SEATS"
                            component={NavLink}
                            to={'/subscription'}
                        >
                            ADD SEATS
                        </Button>
                        <Button
                            type="submit"
                            variant="raised"
                            color="primary"
                            className={classNames(classes.changeSubscription, "ml-24")}
                            aria-label="MODULES"
                            component={NavLink}
                            to={'/subscription'}
                        >
                            MODULES
                        </Button>
                    </CardActions>
                </Card>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getSubscriptions: Actions.getSubscriptions,
    }, dispatch);
}

function mapStateToProps({subscriptionsStore})
{
    return {
        subscriptionItems: subscriptionsStore.subscriptions.subscriptionItems,
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionSummary)));