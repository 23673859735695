import axios from 'axios/index';

export const POST_ACTIVATE_CODE = '[ACTIVATE STORE] POST ACTIVATE CODE';
export const SHOW_ACTIVATION_CODE = '[ACTIVATE STORE] SHOW ACTIVATION CODE';
/*
axios.interceptors.request.use(request => {
    console.log('Starting Request', request);
    return request;
});

axios.interceptors.response.use(response => {
    console.log('Response:', response);
    return response;
});
*/

export function postActivationCode(activationCode, orgId)
{
	const postActivation = axios.post('/api/activation/', {
        code: activationCode
    }, {
        params: {orgId: orgId}
    });

	return (dispatch) =>
        postActivation.then((response) =>
            dispatch({
                type: POST_ACTIVATE_CODE,
                payload: response.data
            })
        );
}

export function showActivationCode(shouldShow)
{
    return (dispatch) =>
        dispatch({
            type: SHOW_ACTIVATION_CODE,
            payload: shouldShow,
        });
}
