import InvoicesMain from 'main/content/invoices/InvoicesMain';
import authRoles from 'auth/authRoles';
export const InvoicesConfig = {
	auth: authRoles.billing,
	routes: [
		{
			path: '/invoices',
			component: InvoicesMain
		}
	]
};