import React, { Component } from 'react';

import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {AppBar, Card, CardContent, Icon, Toolbar, Typography} from '@material-ui/core';
import * as Actions from '../../../store/actions/fuse'

const styles = theme => ({
	appBar: {
		backgroundColor: "#187DC3"
	},
	balanceDue: {
		fontSize: 30
	},
    button: {
    	backgroundColor: '#187DC3'
    },
    card: {
    	flexBasis: "40%",
    	margin: 20
    },
    cardContent: {
    	paddingLeft: 70
    }
});

class Welcome extends Component {
	
	render()
	{
		/* VARIABLES */
		const {classes} = this.props;

		return (
			<Card className={classNames(classes.card, "")}>

				<AppBar
                    position="static"
                    className={classes.appBar}
                >
                    <Toolbar>
                        <Icon className="text-32 mr-12">description</Icon>
                        <Typography variant="headline" color="inherit">
                            Important News
                        </Typography>
                    </Toolbar>
                </AppBar>

				<CardContent className={classes.cardContent}>

					<Typography variant="subheading" color="inherit">
                        <b>New to Portal!</b><br/>
						Documentation<br/>
                        Autopay for multiple subscriptions
                    </Typography>

				</CardContent>

			</Card>
		)
	}
}

export default withStyles(styles, {withTheme: true})(Welcome);
