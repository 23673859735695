import React, { Component } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {FusePageSimple} from '@fuse';

import AutopayHeader from './AutopayHeader';
import AutopayForm from  './AutopayForm';
import ErrorBoundary from '../errors/ErrorBoundary';
import * as PaymentMethodActions from '../payment-methods/store/actions/index';
import * as SubscriptionActions from '../subscriptions/store/actions/index';
import * as AutopayActions from '../autopay/store/actions/index';

class Autopay extends Component {

	componentDidMount() {
		const {
			getSubscription,
			getCreditMethods,
			getBankMethods,
			match,
			user
		} = this.props	

		return Promise.all([
			getSubscription(match.params.subscriptionId, match.params.organizationId),
			getCreditMethods(user.orgId),
			getBankMethods(user.orgId)
		])
	}

	render()
	{
		const { setAutoPay, creditMethods, bankMethods, subscriptions, user, match } = this.props;
		const subscription = subscriptions[match.params.subscriptionId]

		return (
			<React.Fragment>
				<FusePageSimple
					header={
						<AutopayHeader />
					}
					content={
						<ErrorBoundary>
							<AutopayForm 
								creditMethods={creditMethods}
								bankMethods={bankMethods}
								subscription={subscription}
								setAutoPay={setAutoPay}
								user={user}/>
						</ErrorBoundary>
					}
				/>
			</React.Fragment>
		)
	}
}

function mapDispatchToProps(dispatch)
{
	return {		
		getSubscription(subId, orgId) {
			return dispatch(SubscriptionActions.getSubscription(subId, orgId))
		},
        getCreditMethods(id) {
			return dispatch(PaymentMethodActions.getCreditMethods(id))
		},
		getBankMethods(id) {
			return dispatch(PaymentMethodActions.getBankMethods(id))
		},
		setAutoPay(CreditMethod, id) {
			return dispatch(AutopayActions.setAutopay(CreditMethod, id))
		}
	};
}

function mapStateToProps(state)
{	
	return {
		creditMethods: state.creditMethodsReducer.creditMethods.entities,
		bankMethods: state.bankMethodsReducer.bankMethods.entities,
		subscriptions: state.subscriptionsReducer.subscriptions,
		user: state.auth.user.data
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Autopay));