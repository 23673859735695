import axios from 'axios/index';

export const GET_INVOICES = '[INVOICES] GET INVOICES';
export const GET_INVOICE_PDF = '[INVOICES] GET INVOICE PDF'
export const SELECT_ALL_INVOICES = '[INVOICES] SELECT ALL INVOICES';
export const SELECT_INVOICE = '[INVOICE] SELECT INVOICE';
export const SHOW_SELECT_PAYMENT_METHOD = '[INVOICES] SELECT PAYMENT METHOD SCREEN'
export const DESELECT_ALL_INVOICES = '[INVOICES] DESELECT ALL INVOICES';
export const TOGGLE_IN_SELECTED_INVOICES = '[INVOICES] TOGGLE IN SELECTED INVOICES';
export const GET_CREDIT_METHODS = '[CREDIT_METHODS] GET CREDIT METHODS';
export const GET_BANK_METHODS = '[INVOICES] GET BANK METHODS';
export const TOGGLE_IN_SELECTED_CREDIT_METHOD = '[INVOICES] TOGGLE IN SELECTED CREDIT METHODS';
export const GET_BALANCE = '[INVOICES] GET BALANCE';
export const MAKE_PAYMENT = '[INVOICES] MAKE PAYMENT';
export const FAILED_PAYMENT = '[INVOICES] PAYMENT FAILED';

/*
This action describes that all invoices should be retrieved.
*/
export function getInvoices(organizationId)
{
	/*  
	The `routeParams` come from the `ListConfig.js` file.
    It's the text after the last `/` in the path unless there's only one `/`.
    In that case the `routeParams` will show as {} instead of something like `{id: "all"}`.
    */
	const request = axios.get('/api/invoice/', {
		params: {orgId: organizationId}
	});

	return (dispatch) =>
		request.then((response) =>
			dispatch({
				type: GET_INVOICES,
				payload: response.data,
			}),
		);
}

/* Download an invoice from the microservice. */
export function getInvoiceDownload(invoiceId)
{
	return function() {
		return axios('/api/invoice/'+invoiceId, {
			method: 'GET',
			responseType: 'blob', //Force to receive data in a Blob Format
			headers: { 'Content-Type': 'application/pdf'},
			data: {}
		})
		.then(response => {
			let filename = 'JST Invoice ' + invoiceId + '.pdf';
			let blob = new Blob([response.data]);
			if (navigator.appVersion.toString().indexOf('.NET') > 0) {
				window.navigator.msSaveBlob(blob, filename);
			} else { 
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', filename); //or any other extension
				document.body.appendChild(link);
				link.click();
			}
		})
	}
}

export function getBalance(organizationId)
{
    const request = axios.get('/api/invoice/', {
        params: {orgId: organizationId} //TODO: use correct params here
    });

    return (dispatch) =>
        request.then((response) =>
            dispatch({
                type: GET_BALANCE,
                payload: response.data,
            }),
        );
}

export function selectAllInvoices()
{
    return {
        type: SELECT_ALL_INVOICES
    }
}

export function selectInvoice(invoiceId) {
	return {
		type: SELECT_INVOICE,
		invoiceId
	}
}

export function deselectAllInvoices()
{
    return {
        type: DESELECT_ALL_INVOICES
    }
}

export function toggleInSelectedInvoices(invoiceId)
{
    return {
        type: TOGGLE_IN_SELECTED_INVOICES,
        invoiceId,
    }
}

export function showSelectPaymentMethod() {
	return {
		type: SHOW_SELECT_PAYMENT_METHOD
	}
}

export function getCreditMethods(organizationId)
{
	const request = axios.get('/api/payment-method/creditcard/', {
		params: {orgId: organizationId}
	});

	return (dispatch) =>
        request.then((response) =>
			dispatch({
				type: GET_CREDIT_METHODS,
				payload: response.data,
			})
		);
}

export function toggleInSelectedCreditMethod(creditMethodId)
{
	return {
		type: TOGGLE_IN_SELECTED_CREDIT_METHOD,
		creditMethodId
	}
}

/*
This action describes that all bank methods should be returned.
*/
export function getBankMethods(organizationId)
{

	const request = axios.get('/api/payment-method/ach/', {
		params: {orgId: organizationId}
	});

	return (dispatch) =>
		request.then((response) =>
			dispatch({
				type: GET_BANK_METHODS,
				payload: response.data,
			})
		);
}

export function makePayment(accountInfo, organizationId) {

    const postPayment = axios.post('/api/payment/', {
        "organizationId": organizationId,
		"appliedTo": accountInfo.appliedTo,
		"paymentMethodId": accountInfo.paymentMethodId,
		"paymentType": accountInfo.paymentType,
		"amount": accountInfo.amount,
    });

    return (dispatch) =>
		postPayment.then(
			(response) => {
				dispatch({
					type: MAKE_PAYMENT,
					payload: response.data,
				})
			},
			(error) => {
				dispatch({
					type: FAILED_PAYMENT,
					payload: error.response,
				})
			}
		);
}
