import authRoles from 'auth/authRoles';
export const fuseNavigationConfig = [
    /*{
        'id'   : 'subscription-component',
        'title': 'CollectMax',
        'type' : 'item',
        'icon' : 'settings',
        'url'  : '/subscription'
    },*/
    {
        'id'   : 'users-component',
        'title': 'Users',
        'type' : 'collapse',
        'icon' : 'people',
        'auth' : authRoles.admin,
        'children': [
            {
                'id': 'users-list',
                'title': 'Users and Invites',
                'type': 'item',
                'icon': 'people',
                'url': '/users/list'
            },
            {
                'id': 'user-invite',
                'title': 'Invite',
                'type': 'item',
                'icon': 'person_add',
                'url': '/users/invite'
            }
        ]
    },
    {
        'id'   : 'billing-component',
        'title': 'Billing',
        'type' : 'collapse',
        'icon' : 'attach_money',
        'auth' : authRoles.billing,
        'children': [
            {
                'id': 'invoices-component',
                'title': 'Invoices',
                'type': 'item',
                'icon' : 'description',
                'url'  : '/invoices'
            },
            {
                'id': 'payment-methods-component',
                'title': 'Payment Methods',
                'type': 'item',
                'icon' : 'credit_card',
                'url'  : '/payment-methods',
            },
            {
                'id': 'autopay-component',
                'title': 'Autopay',
                'type': 'item',
                'icon' : 'timer',
                'url'  : '/autopay'
            }
        ]
    },
    {
        'id'   : 'products-component',
        'title': 'Subscriptions',
        'type' : 'item',
        'icon' : 'business_center',
        'url'  : '/products',
        'auth' : authRoles.billing
    },        
    {
        'id'   : 'active-component',
        'title': 'Activate',
        'type' : 'item',
        'icon' : 'power_settings_new',
        'url'  : '/activate',
        'auth' : authRoles.support
    },
    {
        'id'   : 'document-component',
        'title': 'Documentation',
        'type' : 'item',
        'icon' : 'file_copy',
        'url'  : '/documents',
        'auth' : authRoles.support
    },    
    {
        'id'   : 'register-component',
        'title': 'Register',
        'type' : 'item',
        'icon' : 'assignment',
        'url'  : '/organization',
        'auth' : authRoles.superadmin
    },
    {
        'id'   : 'profile-component',
        'title': 'My Profile',
        'type' : 'item',
        'icon' : 'perm_identity',
        'url'  : '/myprofile',
        'auth' : authRoles.support
    },
    {
        'id'   : 'contact-component',
        'title': 'Contact Us',
        'type' : 'item',
        'icon' : 'info',
        'url'  : '/contact'
    },
    {
        'id'   : 'logout-component',
        'title': 'Logout',
        'type' : 'item',
        'icon' : 'logout',
        'url'  : '/logout'
    }
];