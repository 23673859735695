import * as Actions from '../actions';
import _ from 'lodash';

const initialState = {
    entities            : [],
    invites             : [],
    roles               : [],
    routeParams         : {},
    userDialog          : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const userReducer = function (state = initialState, action) {
    switch (action.type)
    {
        case Actions.VALIDATE_INVITE:
        {   
            return {
                ...state,
                invite: action.payload
            }
        }
        case Actions.GET_USER_LIST:
        {
            return {
                ...state,
                entities    : _.keyBy(action.payload, 'id'),
                routeParams : action.routeParams
            };
        }
        case Actions.GET_INVITES:
        {
            return {
                ...state,
                invites: action.payload,
                routeParams: action.routeParams
            };
        }
        case Actions.INVITE_USER:
        {
            const newInvite = action.newInvite;
            let invites = [...state.invites];

            invites = [...invites, newInvite];
            return {
                ...state,
                invites: invites
            };
        }
        case Actions.OPEN_NEW_USER_DIALOG:
        {
            console.log("In reducer... opening new user dialog. Data received = ");
            console.log(state);
            return {
                ...state,
                userDialog: {
                    type: 'new',
                    props: {
                        open: true
                    },
                    data: null
                }
            };
        }
        case Actions.CLOSE_NEW_USER_DIALOG:
        {
            return {
                ...state,
                userDialog: {
                    type: 'new',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case Actions.OPEN_EDIT_USER_DIALOG: {
            return {
                ...state,
                userDialog: {
                    type: 'edit',
                    props: {
                        open: true
                    },
                    data: action.data
                }
            }
        }
        case Actions.CLOSE_EDIT_USER_DIALOG:
        {
            return {
                ...state,
                userDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.GET_ROLES:
        {
            return {
                ...state,
                roles: _.keyBy(action.payload, 'id'),
                routeParams : action.routeParams
            };
        }
        default:
        {
            return state;
        }
    }
};



export default userReducer;