/* React / Redux */
import React, {Component} from 'react';

/* Node Modules */
import {FusePageSimple} from '@fuse';

/* Project Imports */
import MyUserHeader from 'main/content/myuser/MyUserHeader';
import MyUserForm from 'main/content/myuser/MyUserForm';

class MyUser extends Component {

    render()
    {
        return (
            <React.Fragment>
                <FusePageSimple
                    header={
                        <MyUserHeader />    
                    }
                    content={
                        <MyUserForm />
                    }
                />
            </React.Fragment>
        )
    };
}

export default MyUser;