import React, {Component} from 'react';
import {Hidden, Icon, IconButton, Typography} from '@material-ui/core';

class InvoicesHeader extends Component {
	render()
    {
        const {pageLayout} = this.props;
        return (
            <div className={"flex flex-1 flex-col sm:flex-row items-center justify-between p-24"}>
                <div className="flex flex-1 items-center">
                    {/* <Hidden lgUp>
                        <IconButton
                            onClick={(ev) => pageLayout().toggleLeftSidebar()}
                            aria-label="open left sidebar"
                        >
                            <Icon>menu</Icon>
                        </IconButton>
                    </Hidden> */}

                    <div className="flex items-center">
                        <Icon className="text-32 mr-12">attach_money</Icon>
                        <Typography variant="headline">Billing</Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default InvoicesHeader;