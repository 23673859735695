import UsersPage from './UsersPage';
import UsersInvite from './UsersInvite';
import UsersRegistration from './UsersRegistration';
import {authRoles} from '../../../auth';

export const UsersConfig = {
    routes  : [
        {
            path: '/users/registration/:invitationCode',
            auth: authRoles.onlyGuest,
            component: UsersRegistration,
            settings: {
                layout: {
                    navbar: 'none',
                    toolbar   : 'none',
                    footer    : 'none'
                }
            }
        },
        {
            auth: authRoles.admin,
            path    : '/users/invite',
            component: UsersInvite,
        },
        {
            path     : '/users/list',
            auth: authRoles.admin,
            component: UsersPage,
        },
    ]
};
