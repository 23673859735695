import jwtDecode from 'jwt-decode';
import axios from 'axios';
import {AUTH_CONFIG} from './jwtServiceConfig';

class jwtService {
    constructor()
    {
        if ( jwtService.instance )
        {
            return jwtService.instance;
        }
        jwtService.instance = this;
    };

    storeToken = (token) => {

        if ( token )
        {
            // Set the time that the access token will expire at
            //let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
            localStorage.setItem('access_token', token);
            //localStorage.setItem('expires_at', expiresAt);
        }
    };

    logout = () => {
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token');
        //localStorage.removeItem('expires_at');
    };

    isAuthenticated = () => {
        // TODO: Need to check the signature of the token too
        let decoded = this.getTokenData();
        if(!decoded) {
            return false;
        }

        let expiration = decoded.exp;
        let now = Math.round(new Date().getTime() / 1000);

        if(now < expiration) {
            return true;
        }
        else
        {
            this.logout();
            return false;
        }
    };

    getUserData = () => {
        this.getTokenData();
    };

    updateUserData = (user_metadata) => {
        const tokenData = this.getTokenData();
        const {sub: userId} = tokenData;

        const auth0UserUrl = 'https://' + AUTH_CONFIG.domain + '/api/v2/users/' + userId;
        const dataObj = JSON.stringify({user_metadata});

        return axios.patch(auth0UserUrl, dataObj, {
            headers: {
                'Content-Type' : 'application/json',
                'Authorization': 'Bearer ' + this.getAccessToken()
            }
        });
    };

    getAccessToken = () => {
        return localStorage.getItem('access_token');
    };

    getTokenData = () => {
        const token = this.getAccessToken();
        if(!token) {
            return null;
        }

        const decoded = jwtDecode(token);

        if ( !decoded )
        {
            return null;
        }

        return decoded;
    }
}

const instance = new jwtService();

// prevents new properties from being added to the object
Object.freeze(instance);

export default instance;
