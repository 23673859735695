import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
	root: {}
});

class SubscriptionsHeader extends Component {
	render()
	{
		const {classes} = this.props;
		return (
			<div className = {classNames(classes.root, "flex flex-1 flex-col sm:flex-row items-center justify-between p-24")}>
				<div className="flex flex-1 items-center">
                    <div className="flex items-center">
                        {/*<Icon className="text-32 mr-12">credit_card</Icon>*/}
                        <Typography variant="title">APIvista Portal</Typography>
                    </div>
                </div>
            </div>
		);
	}
}

export default withStyles(styles, {withTheme: true})(SubscriptionsHeader);