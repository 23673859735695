import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Icon, Typography} from '@material-ui/core';

const styles = theme => ({
});

class UsersHeader extends Component {
	render()
	{
		return (
			<div className="flex flex-1 flex-col sm:flex-row items-center justify-between p-24">
				<div className="flex flex-1 items-center">
                    <div className="flex items-center">
                        <Icon className="text-32 mr-12">people</Icon>
                        <Typography variant="headline">Users and Invites</Typography>
                    </div>
                </div>
            </div>
		);
	}
}

export default withStyles(styles, {withTheme: true})(UsersHeader);