import React from 'react';

import {Button, Card, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles/index';

const styles = theme => ({
  card: {
    padding: 24
  },
  errorBlock: {
    flexBasis: "40%",
    margin: 20,
  },
  reloadButton: {
    backgroundColor: '#187DC3',
    marginTop: 24
  }
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {

      const {classes} = this.props;

      return (
          <div className={classes.errorBlock}>
            <Card className={classes.card}>
              <Typography variant="headline">Oops...It looks like an error has occurred.</Typography>
              <Typography variant="subheading">You may want to refresh the page and try again.</Typography>
              {/*<details style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo.componentStack}
              </details>*/}
              <Button
                className={classes.reloadButton}
                variant="raised"
                color="primary"
                onClick={() => {
                  window.location.reload()
                }}
              >
                REFRESH PAGE
              </Button>
            </Card>
          </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}

export default withStyles(styles, {withTheme: true})(ErrorBoundary);