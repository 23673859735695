import React, { Component } from 'react';

import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import {FusePageSimple} from '@fuse';
import {AppBar, Card, CardContent, Icon, Toolbar, Typography} from '@material-ui/core';

import ContactHeader from 'main/content/contact/ContactHeader';

const styles = theme => ({
	appBar: {
		backgroundColor: "#187DC3"
	},
    button: {
    	margin: theme.spacing.unit,
    	backgroundColor: '#187DC3'
    },
    card: {
		minWidth: 275,
	},
	contactIcons: {
		color: '#187DC3'
	}
});

class Contact extends Component {

	render()
	{
		/* VARIABLES */
		const {classes} = this.props;

		return (

			<React.Fragment>
				<FusePageSimple
					header={
						<ContactHeader />
					}
					content={
						<div>
							<Card className={classNames(classes.card, "max-w-lg mt-24 mb-24 ml-24")}>
								<AppBar
				                    position="static"
				                    className={classNames(classes.appBar, "mb-24")}
				                >
				                    <Toolbar>
				                        <Icon className="text-32 mr-12">info</Icon>
				                        <Typography variant="headline" color="inherit">
				                            You can reach us by phone or email
				                        </Typography>
				                    </Toolbar>
				                </AppBar>
								<CardContent>
									<div style={{ display: 'flex'}}>
										<Icon className={classNames(classes.contactIcons, "text-32 mr-12")}>phone</Icon>
										<Typography variant="headline" className="">Main Number/Billing:
										</Typography>
									</div>
									<Typography className="ml-44 mb-24 text-18">804-288-7850
									</Typography>

									<div style={{ display: 'flex'}}>
										<Icon className={classNames(classes.contactIcons, "text-32 mr-12")}>phone</Icon>
										<Typography variant="headline" className="">Sales:
										</Typography>
									</div>
									<Typography className="ml-44 mb-24 text-18">1-800-827-1457
									</Typography>

									<div style={{ display: 'flex'}}>
										<Icon className={classNames(classes.contactIcons, "text-32 mr-12")}>email</Icon>
										<Typography variant="headline" className="">Email:
										</Typography>
									</div>
									<Typography className="ml-44 text-18"><a href='mailto:billing@collectmax.com'>Billing@CollectMax.com</a>
									</Typography>
									<Typography className="ml-44 mb-24 text-18"><a href='mailto:sales@collectmax.com'>Sales@CollectMax.com</a>
									</Typography>


								</CardContent>
							</Card>
						</div>
					}
				/>
			</React.Fragment>
		)
	}
}

export default withStyles(styles, {withTheme: true})(Contact);