import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {FuseUtils} from '@fuse';
import {Icon, IconButton} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import * as Actions from './store/actions';
import ReactTable from "react-table";
import classNames from 'classnames';
import _ from 'lodash';

const styles = theme => ({
});

class UsersList extends Component {

    constructor(props) {
        super(props);
        this.state = {hasError: null};
    }

    componentDidMount() {
        //if(this.props.orgId !== undefined && this.props.orgId > 0) {
            this.props.getUsers(this.props.orgId)
                .catch((e) => this.handleError());
        //}
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
            console.log("reload triggered");
            this.props.getUsers(this.props.orgId)
                .catch((e) => this.handleError());

		}
    }

    handleError() {
        this.setState({ hasError: true });
    };

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if ( searchText.length === 0 )
        {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    render() {
        const {users, classes, openEditUserDialog} = this.props;
        const data = this.getFilteredArray(users,"");

        return (
            <div>
                <ReactTable 
                    className={classNames(classes.root, "-striped -highlight")}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            className   : "cursor-pointer",
                            onClick     : (e, handleOriginal) => {
                                console.log("Clicked open invite user: ");
                                console.log(e);
                                console.log(handleOriginal);

                                if (rowInfo ) {
                                    openEditUserDialog(rowInfo.original);
                                }
                            }
                        }
                    }}
                    columns={[
                        {
                            columns: [
                                {
                                    Header      : "First Name",
                                    accessor    : "firstName",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 150
                                },
                                {
                                    Header      : "Last Name",
                                    accessor    : "lastName",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 175
                                },
                                {
                                    Header      : "Email",
                                    accessor    : "emailAddress",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 300
                                },
                                {
                                    Header      : "Phone",
                                    accessor    : "phoneNumber",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 150
                                },
                                {
                                    Header      : "Role",
                                    accessor    : "role",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 150
                                },
                                {
                                    Header      : "Status",
                                    accessor    : "isActive",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 100,
                                    Cell: ({value}) => (value === true ? "Active" : "Deactivated")
                                },
                                {
                                    Header      : "Actions",
                                    accessor    : "actions",
                                    className: "justify-center",
                                    headerStyle: {
                                        fontWeight: "bold"
                                    },
                                    minWidth: 100,
                                    Cell: ({value}) => <IconButton><Icon>edit</Icon></IconButton>
                                }
                            ]
                        }
                    ]}
                    data = {data}
                    defaultPageSize={5}
                    noDataText="No users found"
                    showPagination={data.length > 5}
                    style={{
                        height: 350
                    }}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getUsers            : Actions.getUserList,
        openEditUserDialog  : Actions.openEditUserDialog
    }, dispatch);
}

function mapStateToProps(state)
{
    return {
        users              : state.userStore.users.entities,
        orgId              : state.auth.user.data.orgId
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersList)));