import axios from 'axios/index';
import {setUserDataFromJWT} from 'auth/store/actions/user.actions';
import * as GlobalActions from '../../../store/actions';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export function submitLogin({email, password})
{
    const request = axios.post('/api/auth/login', {
        email,
        password
    });
    return (dispatch) =>
        request.then((response) => {
            if ( !response.data.error )
            {
                dispatch(setUserDataFromJWT(response.data.token));
                return dispatch({
                    type: LOGIN_SUCCESS
                });
            }
            else
            {
                if (response.data.error === "Locked") {
                    dispatch(GlobalActions.showMessage({message: "Your account is locked. Please try again in a few minutes."}));
                }
                else {
                    dispatch(GlobalActions.showMessage({message: "Invalid email address or password"}));
                }
                return dispatch({
                    type   : LOGIN_ERROR,
                    payload: response.data.error
                });
            }
        })
}


export function resetPassword({email})
{
    const request = axios.post('/api/auth/password-reset', {
        email,
    });
    return (dispatch) =>
        request.then((response) => {
            if ( !response.data.error )
            {
                dispatch(GlobalActions.showMessage({message: "Password reset request accepted, and email sent."}));
                return dispatch({
                    type: RESET_PASSWORD
                });
            }
            else
            {
                dispatch(GlobalActions.showMessage({message: "Error submitting reset request."}));
                return dispatch({
                    type   : LOGIN_ERROR,
                    payload: response.data.error
                });
            }
        })
}
