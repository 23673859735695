import Welcome from './Welcome';
import authRoles from "../../../auth/authRoles";

export const WelcomeConfig = {
    auth: authRoles.allUsers,
	routes: [
		{
			path: '/welcome',
			component: Welcome,
		}
	]
};