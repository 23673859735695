/* React / Redux */
import React, {Component} from 'react';

/* Node Modules */
import {withStyles} from '@material-ui/core/styles';

/* Theme Imports */
import {FusePageSimple} from '@fuse';


/* Project Imports */
import SubscriptionsHeader from 'main/content/subscriptions/SubscriptionsHeader';
import SubscriptionsSummary from 'main/content/subscriptions/SubscriptionsSummary';

const styles = theme => ({
    root: {},
});

class Subscriptions extends Component {

    render()
    {     
        return (
            <React.Fragment>
                <FusePageSimple
                    header={
                        <SubscriptionsHeader pageLayout = {() => this.pageLayout}/>
                    }
                    content={
                        <SubscriptionsSummary />
                    }
                />
            </React.Fragment>
        )
    }
}

export default withStyles(styles, {withTheme: true})(Subscriptions);