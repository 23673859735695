import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import {AppBar, Button, Icon, IconButton, Tab, Tabs, Toolbar, Typography} from '@material-ui/core';
import {AccountBalance, CreditCard} from '@material-ui/icons';
import ReactTable from "react-table";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as Actions from './store/actions';
import CreditMethodsDialog from './CreditMethodsDialog';
import BankMethodsDialog from './BankMethodsDialog';

const styles = theme => ({
	addButton: {
		position: "relative",
		marginBottom: 24,
		backgroundColor: "#187DC3",
		color: "#ffffff"
	},
	appBar: {
        backgroundColor: "#187DC3"
    },
	labels: {},
	cellStyle: {
		textTransform: 'capitalize',
	},
});

function TabContainer(props)
{
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	);
}

class PaymentMethodsList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: 'one',
			hasError: null
		}
	}

	handleError() {
		this.setState({ hasError: true });
	}

	componentDidMount()
    {
        this.props.getCreditMethods(this.props.match.params)
        .catch((e) => this.handleError());
        this.props.getBankMethods(this.props.match.params)
        .catch((e) => this.handleError());
    }
    componentDidUpdate(prevProps, prevState)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
            this.props.getCreditMethods(this.props.match.params);
            this.props.getBankMethods(this.props.match.params);
        }
    }

	handleChange = (event, value) => {
		this.setState({
			value
		});
	};

	getFilteredArray = (entities) => {
		const arr = Object.keys(entities).map((id) => entities[id]);
		return arr;
	};

	render()
	{
		const {value} = this.state;
		const {classes, creditMethods, bankMethods, openNewCreditMethodDialog, openRemoveCreditMethodDialog, openNewBankMethodDialog, openRemoveBankMethodDialog} = this.props;

		const credit = this.getFilteredArray(creditMethods);
		const bank = this.getFilteredArray(bankMethods);

/*		if (this.state.hasError === true) {
			throw new Error('I crashed!');
		}*/

		return (
			<div>
				<div className={classNames(classes.root, "mt-24 mb-24 ml-24 mr-24")}>
					<AppBar
	                    position="static"
	                    className={classes.appBar}
	                >
	                    <Toolbar>
	                        <Icon className="text-32 mr-12">credit_card</Icon>
	                        <Typography variant="title" color="inherit" className={classes.flex}>
	                            Payment Methods
	                        </Typography>
	                    </Toolbar>
	                </AppBar>
					<AppBar position="static" style={{ backgroundColor: "#ffffff" }}>
						<Tabs
							value={value}
							onChange={this.handleChange}
							style={{ backgroundColor: "#303030" }}
						>
							<Tab value="one" label="Credit Cards" icon={<CreditCard />}/>
							<Tab value="two" label="Bank Accounts" icon={<AccountBalance />} />
						</Tabs>
						{value === 'one' && <TabContainer>
							{/* Add New Credit Method Button */}
			                <Button
			                    variant="raised"
			                    color="primary"
			                    aria-label="add"
			                    className={classes.addButton}
			                    onClick={openNewCreditMethodDialog}
			                >
			                    ADD NEW CREDIT CARD
			                </Button>
							<ReactTable
								className = {classNames(classes.root, "-striped -highlighted")}
								data = {credit}
								columns = {[
									{
										columns: [
											{
												Header: "Card Type",
												accessor: "cardType",
												className: classNames(classes.cellStyle, "justify-center"),
												headerStyle: {
													fontWeight: "bold"
												},
											},
											{
												Header: "Account Number",
												accessor: "maskedNumber",
												className: "justify-center",
												headerStyle: {
													fontWeight: "bold"
												},
											},
											{
												Header: "Expiration Date",
												accessor: "expDate",
												className: "justify-center",
												headerStyle: {
													fontWeight: "bold"
												},
											},
											{
						                        Header: "Actions",
						                        width : 128,
						                        className: "justify-center",
						                        headerStyle: {
													fontWeight: "bold"
												},
						                        Cell  : row => (
						                            <div className="flex items-center">
						                                <IconButton
						                                    onClick={(ev) => {
						                                        ev.stopPropagation();
						                                        openRemoveCreditMethodDialog(row.original);
						                                    }}
						                                >
						                                    <Icon>delete</Icon>
						                                </IconButton>
						                            </div>
						                        ),
						                    }
										]
									}
								]}
								defaultPageSize={5}
				            	noDataText="No credit cards are on file currently."
				            	showPagination={credit.length > 5}
							/>
						</TabContainer>}
						{value === 'two' && <TabContainer>
							{/* Add New Bank Method Button */}
							<Button
			                    variant="raised"
			                    color="primary"
			                    aria-label="add"
			                    className={classes.addButton}
			                    onClick={openNewBankMethodDialog}
			                >
			                    ADD NEW BANK ACCOUNT
			                </Button>
							<ReactTable
								className = {classNames(classes.root, "-striped -highlighted")}
								data = {bank}
								columns = {[
									{
										columns: [
											{
												Header: "Bank Name",
												accessor: "bankName",
												className: classNames(classes.cellStyle, "justify-center"),
												headerStyle: {
													fontWeight: "bold"
												},
											},
											{
												Header: "Account Number",
												accessor: "maskedAccount",
												className: "justify-center",
												headerStyle: {
													fontWeight: "bold"
												},
											},
											{
												Header: "Routing Number",
												accessor: "maskedRouting",
												className: "justify-center",
												headerStyle: {
													fontWeight: "bold"
												},
											},
											{
												Header: "Type",
												accessor: "type",
												className: classNames(classes.cellStyle, "justify-center"),
												headerStyle: {
													fontWeight: "bold"
												},
											},
											{
						                        Header: "Actions",
						                        width : 128,
						                        className: "justify-center",
						                        headerStyle: {
													fontWeight: "bold"
												},
						                        Cell  : row => (
						                            <div className="flex items-center">
						                                <IconButton
						                                    onClick={(ev) => {
						                                        ev.stopPropagation();
						                                        openRemoveBankMethodDialog(row.original);
						                                    }}
						                                >
						                                    <Icon>delete</Icon>
						                                </IconButton>
						                            </div>
						                        ),
						                    }
										]
									}
								]}
								defaultPageSize={5}
				            	noDataText="No bank accounts are on file currently."
                                showPagination={bank.length > 5}
							/>
						</TabContainer>}
					</AppBar>

					{/* Modal Popup */}
	                <CreditMethodsDialog/>

	                {/* Modal Popup */}
	                <BankMethodsDialog/>
				</div>
			</div>
		);

	}
}

PaymentMethodsList.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch)
{
	return bindActionCreators({
		getCreditMethods: Actions.getCreditMethods,
		getBankMethods: Actions.getBankMethods,
		openNewCreditMethodDialog: Actions.openNewCreditMethodDialog,
		openRemoveCreditMethodDialog: Actions.openRemoveCreditMethodDialog,
		openNewBankMethodDialog: Actions.openNewBankMethodDialog,
		openRemoveBankMethodDialog: Actions.openRemoveBankMethodDialog,
	}, dispatch);
}

function mapStateToProps({bankMethodsReducer, creditMethodsReducer})
{
	return {
		creditMethods: creditMethodsReducer.creditMethods.entities,
		bankMethods: bankMethodsReducer.bankMethods.entities
	}
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsList)));