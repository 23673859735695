import React from 'react';
import {NavLink} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles/index';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';

const styles = theme => ({
    root    : {
        display   : 'flex',
        alignItems: 'center'
    },
    logo    : {},
    logoIcon: {
        top: 20,
        left: 12,
        width : 42,
        height: 28,
        position: 'absolute',
        zIndex: 999
    }
});

function MainNavbarHeader({classes})
{
    return (
        <div className={classes.root}>
            <div className={classNames(classes.logo, "flex items-center")}>
                <NavLink to="/welcome">
                    <img className={classes.logoIcon} src="assets/images/logos/jst_logo.png" alt="logo" />
                </NavLink>
            </div>
        </div>
    );
};

export default withStyles(styles, {withTheme: true})(withRouter(MainNavbarHeader));
