import axios from 'axios/index';
import history from '../../../../../history';

export const VALIDATE_INVITE = '[USER STORE] VALIDATE INVITE';
export const GET_USER_LIST = '[USER STORE] GET USER DATA';
export const GET_INVITES = '[USER STORE] GET INVITES';
export const INVITE_USER = '[USER STORE] INVITE USER';
export const ACCEPT_INVITE = '[USER STORE] ACCEPT INVITE';
export const ADD_USER = '[USER STORE] ADD USER';
export const UPDATE_USER = '[USER STORE] UPDATE USER';
export const UPDATE_PASSWORD = '[USER STORE] UPDATE PASSWORD';
export const OPEN_NEW_USER_DIALOG = '[USER STORE] OPEN NEW USER DIALOG';
export const CLOSE_NEW_USER_DIALOG = '[USER STORE] CLOSE NEW USER DIALOG';
export const OPEN_EDIT_USER_DIALOG = '[USER STORE] OPEN EDIT USER DIALOG';
export const CLOSE_EDIT_USER_DIALOG = '[USER STORE] CLOSE EDIT USER DIALOG';
export const GET_ROLES = '[USER STORE] GET ROLES';

export function validateInvite(invitationCode)
{
    const getInvitation = axios.get('/api/invitation/' + invitationCode);

    return (dispatch) =>
        getInvitation
        .then((response) =>
            dispatch({
                type: VALIDATE_INVITE,
                payload: response.data,
                invitationCode
            }))
}

export function getUserList(organizationId)
{
    //TODO: Remove orgId and source it from auth token
    const getUsers = axios.get('/api/user/', {
        params: {orgId: organizationId}
    });

    return (dispatch) =>
        getUsers
        .then((response) =>
            dispatch({
                type   : GET_USER_LIST,
                payload: response.data,
            })
        );
}

export function getInvites(organizationId)
{
    const getInvitations = axios.get('/api/invitation/', {
        params: {orgId: organizationId}
    });
    return (dispatch) =>
        getInvitations
        .then((response) =>
            dispatch({
                type: GET_INVITES,
                payload: response.data,
            }))
        .catch((error) =>
            history.push('/error')
        );
}

export function inviteUser(newInvite)
{
    return (dispatch, getState) => {

        const request = axios.post('/api/invitation/', newInvite);

        return request
        .catch((error) =>
            console.log(error)
        )
        .then((response) =>
            Promise.all([
                dispatch({
                    type: INVITE_USER
                })
            ])
            .catch((error) => {
                console.log('Error = ' + error)
            })
            .then(() => dispatch(getInvites(response.data.organizationId)))
        );
    };
}

export function acceptInvitation(invite)
{
    return (dispatch) => {
        const request = axios.post('/api/invitation/' + invite.invitationCode, {
            firstName: invite.firstName,
            lastName: invite.lastName,
            emailAddress: invite.emailAddress,
            phoneNumber: invite.phoneNumber,
            password: invite.password,
            invitationCode: invite.invitationCode,
        });

        return request
        .then((response) => {
            Promise.all([
                dispatch({
                    type: ACCEPT_INVITE,
                    payload: response.data
                })
                ]).then(() => {
                    history.push('/login');
                })
            }
        );
    };
}

export function addUser(newUser)
{
    return (dispatch, getState) => {

        const {routeParams} = getState().userStore.users;

        const request = axios.post('/api/user/', {
            newUser
        });

        return request
        .then((response) =>
            Promise.all([
                dispatch({
                    type: ADD_USER
                })
            ])

            .catch((error) =>
                this.history.push('/error')
            )
            .then(() => dispatch(getUserList(routeParams)))
            .catch((error) =>
                this.history.push('/error')
            )
        )
        .catch((error) =>
            this.history.push('/error')
        );
    };
}

export function updateUser(user)
{
    return (dispatch, getState) => {

        const request = axios.put('/api/user/' + user.id, {
            firstName: user.firstName,
            lastName: user.lastName,
            emailAddress: user.emailAddress,
            phoneNumber: user.phoneNumber,
            role: user.role,
        });

        return request
        .catch((error) =>
            this.history.push('/error')
        )
        .then((response) =>
            Promise.all([
                dispatch({
                    type: UPDATE_USER
                })
            ])
            .catch((error) =>
                this.history.push('/error')
            )
            .then(() => dispatch(getUserList(user.organizationId)))
            .catch((error) =>
                this.history.push('/error')
            )
        );
    };
}

export function updatePassword(user)
{
    return (dispatch, getState) => {
        const request = axios.post('/api/user/' + user.id + '/password', {
            password: user.password
        });

        return request
            .catch(() =>
                this.history.push('/error')
            )
            .then(() =>
                dispatch({
                    type: UPDATE_PASSWORD
                })
            );
    };
}

export function getRoles(organizationId)
{
    const request = axios.get('/api/user/roles/', {
        params: {orgId: organizationId}
    });

    return (dispatch) =>
        request
        .catch((error) =>
            this.history.push('/error')
        )
        .then((response) =>
            dispatch({
                type   : GET_ROLES,
                payload: response.data,
            }))
        .catch((error) =>
            this.history.push('/error')
        );
}

export function openNewUserDialog() 
{
    return {
        type: OPEN_NEW_USER_DIALOG
    }
}

export function closeNewUserDialog() {
    return {
        type: CLOSE_NEW_USER_DIALOG
    }
}

export function openEditUserDialog(data)
{
    return {
        type: OPEN_EDIT_USER_DIALOG,
        data
    }
}

export function closeEditUserDialog()
{
    return {
        type: CLOSE_EDIT_USER_DIALOG
    }
}