import LogoutPage from './Logout';

export const LogoutConfig = {
    settings: {
        layout: {
            navbar: 'none',
            toolbar   : 'none',
            footer    : 'none'
        }
    },
    routes  : [
        {
            path     : '/logout',
            component: LogoutPage
        },
    ]
};
