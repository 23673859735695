import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles/index';
import {Hidden, Icon, IconButton, Typography} from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
    dogImage: {
        width: 120
    },
    welcome: {
        color: "#ffffff"
    }
});

class WelcomeHeader extends Component {
	render()
    {
        const {classes, pageLayout} = this.props;
        return (
            <div className="flex flex-1 flex-col sm:flex-row items-center justify-between p-24">

                <div className="flex flex-1 items-center">

                    <div className="flex items-center text-center min-w-full">
                        <img className={classes.dogImage} src='assets/images/logos/Max1.jpg' alt='Max the dog illustration'/>
                        <Typography className={classNames(classes.welcome, "mx-auto")} variant="display2">Subscriptions</Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(WelcomeHeader);