import React, {Component} from 'react';

import {FusePageSimple} from '@fuse';

import ActivateHeader from 'main/content/activate/ActivateHeader';
import ActivateForm from 'main/content/activate/ActivateForm';
import ErrorBoundary from 'main/content/errors/ErrorBoundary';

class Activate extends Component {

	render()
	{
		return (
			<React.Fragment>
				<FusePageSimple
					header={
						<ActivateHeader />
					}
					content={
						<ErrorBoundary>
							<ActivateForm />
						</ErrorBoundary>
					}
				/>
			</React.Fragment>
		)
	}
}

export default Activate;